import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Popover,
  Tab,
  Tabs,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { Add, Search, KeyboardArrowDown } from '@material-ui/icons';
import { selectAgency, selectUser } from '../../../../store/slices/userSlice';
import { useGetInsightsQuery } from '../../../../store/slices/apiSlice';
import {
  addInsightTab,
  setActiveInsight,
} from '../../../../store/slices/layoutSlice';
import { Insight, InsightCreatedBy } from '../../types';
import InsightListTabPanel from './InsightListTabPanel';
import {
  partitionByCreator,
  partitionByType,
} from '../../../../utils/insights';
import { sortAlphaNum, stableSort } from '../../../../utils/utils';
import HeaderMenuButtonV2 from '../../../../components/HeaderMenuButton/HeaderMenuButtonV2';

const useStyles = makeStyles((theme) => ({
  addButton: {
    border: `1px solid ${theme.palette.tertiary.dark}`,
    '& .MuiSvgIcon-root': {
      transition: 'transform 0.1s ease-in-out',
    },
  },
  addButtonActive: {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    '& .MuiSvgIcon-root': {
      transform: 'rotate(45deg)',
    },
  },
}));

type AddInsightButtonProps = {
  onCreateClick?: () => void;
  useV2?: boolean;
  showFavorites?: boolean;
};

export default function AddInsightButton({
  onCreateClick,
  useV2,
  showFavorites,
}: AddInsightButtonProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [tab, setTab] = useState(0);
  const [search, setSearch] = useState('');
  const { userId } = useSelector(selectUser);
  const { agencyId } = useSelector(selectAgency);
  const { data = [], isLoading, isError } = useGetInsightsQuery({ agencyId });
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const sortedData = useMemo(() => {
    let filteredInsights = stableSort(data, (insightA, insightB) =>
      sortAlphaNum(insightA.name, insightB.name)
    );
    if (search) {
      const searchExpression = new RegExp(search, 'gi');
      filteredInsights = filteredInsights.filter((insight) =>
        insight.name.match(searchExpression)
      );
    }

    const favoriteInsights = filteredInsights.filter(
      (insight) => insight.isFavorited === true
    );

    if (userId) {
      const [mine, others] = partitionByCreator(filteredInsights, userId);
      return {
        [InsightCreatedBy.ME]: partitionByType(mine),
        [InsightCreatedBy.NOT_ME]: partitionByType(others),
        favorites: partitionByType(favoriteInsights),
      };
    }

    return {
      [InsightCreatedBy.ME]: [] as ReturnType<typeof partitionByType>,
      [InsightCreatedBy.NOT_ME]: partitionByType(filteredInsights),
      favorites: partitionByType(favoriteInsights),
    };
  }, [data, search, userId]);

  const resetState = useCallback(() => {
    setSearch('');
    setTab(0);
  }, []);

  const handleInsightClick = useCallback(
    (insight: Insight) => {
      setAnchorEl(null);
      if (!useV2) {
        dispatch(
          addInsightTab({
            id: insight.id,
            name: insight.name,
            type: insight.insightType,
          })
        );
      }
      dispatch(setActiveInsight(insight));
      history.push(`/${useV2 ? 'views' : 'insights'}/${insight.id}`);
    },
    [dispatch, history, useV2]
  );

  const button = useV2 ? (
    <HeaderMenuButtonV2
      aria-haspopup='true'
      aria-expanded={!!anchorEl}
      aria-label='Add insight'
      color='inherit'
      disabled={isLoading || isError}
      isActive={!!anchorEl}
      onClick={(e) => setAnchorEl(e.currentTarget)}
      title='Switch view'
      align='left'
    >
      <KeyboardArrowDown />
    </HeaderMenuButtonV2>
  ) : (
    <IconButton
      aria-haspopup='true'
      aria-expanded={!!anchorEl}
      aria-label='Add insight'
      className={clsx(classes.addButton, {
        [classes.addButtonActive]: !!anchorEl,
      })}
      color='secondary'
      disabled={isLoading || isError}
      onClick={(e) => setAnchorEl(e.currentTarget)}
      size='small'
      title='Add insight'
    >
      <Add />
    </IconButton>
  );

  const getTabIndex = (index: number) => {
    return showFavorites ? index + 1 : index;
  };

  return (
    <>
      {button}
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        TransitionProps={{ onExited: resetState }}
      >
        <Box display='flex' flexDirection='column'>
          <Box pt={2} px={2}>
            <TextField
              autoFocus
              fullWidth
              onChange={(e) => setSearch(e.target.value)}
              placeholder='Search'
              variant='outlined'
              value={search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Search fontSize='small' />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        <Tabs
          value={tab}
          variant='fullWidth'
          onChange={(_, value) => setTab(value)}
        >
          {showFavorites && <Tab label='Favorites' id='insight-list-tab-0' />}
          <Tab
            label='Created by you'
            id={`insight-list-tab-${getTabIndex(0)}`}
          />
          <Tab
            label='Created by others'
            id={`insight-list-tab-${getTabIndex(1)}`}
          />
        </Tabs>
        {showFavorites && (
          <InsightListTabPanel
            value={tab}
            index={0}
            data={sortedData['favorites']}
            onInsightClick={handleInsightClick}
          />
        )}
        <InsightListTabPanel
          value={tab}
          index={getTabIndex(0)}
          data={sortedData[InsightCreatedBy.ME]}
          onInsightClick={handleInsightClick}
        />
        <InsightListTabPanel
          value={tab}
          index={getTabIndex(1)}
          data={sortedData[InsightCreatedBy.NOT_ME]}
          onInsightClick={handleInsightClick}
        />
        {onCreateClick && (
          <Box pb={2} px={2} pt={1}>
            <Button
              fullWidth
              variant='contained'
              color='secondary'
              startIcon={<Add />}
              onClick={() => {
                setAnchorEl(null);
                onCreateClick();
              }}
            >
              Create new
            </Button>
          </Box>
        )}
      </Popover>
    </>
  );
}
