import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingSpinner: {
    marginRight: 12,
    color: '#5e6267',
  },
  errorIcon: {
    float: 'left',
    marginRight: 12,
    color: '#d0021b',
  },
  details: {
    float: 'left',
    fontSize: 12,
    color: '#5e6267',
    textDecoration: 'none',
  },
}));
