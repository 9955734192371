import { getCompositeActivityValue, lossyDeepClone } from './utils';
import {
  RelativePeriod,
  isRelativeDateRange,
  getDatesForRelativePeriod,
  getWeekdayWeekendState,
} from './dates';
import moment from 'moment-timezone';
import { BaseActivity, MetricsV1, TagTypeV2 } from '../store/types';

export enum FilterCategory {
  ROUTES = 'routes',
  STOPS = 'stops',
  TRIPS = 'trips',
}

export enum FilterType {
  METRIC = 'metric',
  TAG = 'tag',
}

export type MetricFilter = {
  type: FilterType.METRIC;
  inequality: string;
  metric: string;
  metrictype: {
    id: string;
    name: string;
  };
};

export type TagFilter = {
  type: FilterType.TAG;
  bool: string;
  tagtype_id: string;
  tagtype?: TagTypeV2;
};

export type Filter = MetricFilter | TagFilter;

const getMetricTitle = (
  metricType: MetricType | keyof MetricsV1,
  displayDistanceType?: 'miles' | 'kilometers'
) => {
  switch (metricType) {
    case 'avgOns':
    case 'avg_ons':
      return 'Average daily boardings';
    case 'sumOns':
    case 'sum_ons':
      return 'Total boardings';
    case 'avgOffs':
    case 'avg_offs':
      return 'Average daily alightings';
    case 'sumOffs':
    case 'sum_offs':
      return 'Total alightings';
    case 'avgActivity':
    case 'avg_activity':
      return 'Average daily activity';
    case 'sumActivity':
    case 'sum_activity':
      return 'Total activity';
    case 'avgLoad':
    case 'avg_load':
      return 'Average load';
    case 'maxLoad':
    case 'maxload':
      return 'Max load';
    case 'avgMaxLoad':
      return 'Average daily max load';
    case 'sumLoad':
      return 'Total passenger throughput';
    case 'boardingsPerRevenueHour':
    case 'boardings_per_revenue_hour':
      return 'Boardings per revenue hour';
    case 'avgPassengerDistanceTravelled':
    case 'avg_passenger_distance_travelled':
      return displayDistanceType === 'kilometers'
        ? 'Average daily passenger kilometers travelled'
        : 'Average daily passenger miles travelled';
    case 'sumPassengerDistanceTravelled':
    case 'sum_passenger_distance_travelled':
      return displayDistanceType === 'kilometers'
        ? 'Total passenger kilometers travelled'
        : 'Total passenger miles travelled';
    default:
      return '';
  }
};

const getMetricDescription = (
  metricType: MetricType | keyof MetricsV1,
  displayDistanceType?: 'miles' | 'kilometers'
) => {
  switch (metricType) {
    case 'avgOns':
    case 'avg_ons':
      return 'The average number of boardings per service date';
    case 'sumOns':
    case 'sum_ons':
      return 'The total number of boardings';
    case 'avgOffs':
    case 'avg_offs':
      return 'The average number of alightings per service date';
    case 'sumOffs':
    case 'sum_offs':
      return 'The total number of alightings';
    case 'avgActivity':
    case 'avg_activity':
      return 'The average number of combined boardings and alightings per service date';
    case 'sumActivity':
    case 'sum_activity':
      return 'The total number of combined boardings and alightings';
    case 'avgLoad':
    case 'avg_load':
      return 'The average number of passengers on any given vehicle';
    case 'maxLoad':
    case 'maxload':
      return 'The single highest number of passengers on a vehicle';
    case 'avgMaxLoad':
      return 'The average highest number of passengers on the vehicle per day';
    case 'sumLoad':
      return 'The total number of passengers onboard';
    case 'boardingsPerRevenueHour':
    case 'boardings_per_revenue_hour':
      return 'The average number of boardings per hour of scheduled revenue service';
    case 'avgPassengerDistanceTravelled':
    case 'avg_passenger_distance_travelled':
      return displayDistanceType === 'kilometers'
        ? 'The average passenger kilometers travelled per service date'
        : 'The average passenger miles travelled per service date';
    case 'sumPassengerDistanceTravelled':
    case 'sum_passenger_distance_travelled':
      return displayDistanceType === 'kilometers'
        ? 'The total passenger kilometers travelled'
        : 'The total passenger miles travelled';
    default:
      return '';
  }
};

const filterEquals = (filterA: Filter, filterB: Filter) => {
  if (!filterA || !filterB) {
    return false;
  }

  if (filterA.type === filterB.type) {
    if (filterA.type === FilterType.METRIC) {
      const {
        inequality: inequalityA,
        metric: metricA,
        metrictype: metrictypeA,
      } = filterA;
      const {
        inequality: inequalityB,
        metric: metricB,
        metrictype: metrictypeB,
      } = filterB as MetricFilter;
      return (
        inequalityA === inequalityB &&
        metricA === metricB &&
        metrictypeA.id === metrictypeB.id
      );
    } else if (filterA.type === FilterType.TAG) {
      const { bool: boolA, tagtype_id: tagTypeIdA } = filterA;
      const { bool: boolB, tagtype_id: tagTypeIdB } = filterB as TagFilter;
      return boolA === boolB && tagTypeIdA === tagTypeIdB;
    }
  }

  return false;
};

const removeFilterTagTypes = (filters: Filter[]) => {
  return lossyDeepClone(filters).map((filter) => {
    if (filter.type === FilterType.TAG) {
      delete filter.tagtype;
    }
    return filter;
  });
};

const annotateFilterTagType = (
  filter: Filter,
  category: FilterCategory,
  tagTypes: TagTypeV2[]
) => {
  const annotatedFilter = { ...filter };
  if (annotatedFilter.type === FilterType.TAG) {
    if (category === FilterCategory.ROUTES) {
      annotatedFilter.tagtype = tagTypes.find(
        (tag) =>
          tag.dataType === 'routes' && tag.id === annotatedFilter.tagtype_id
      );
    } else if (category === FilterCategory.STOPS) {
      annotatedFilter.tagtype = tagTypes.find(
        (tag) =>
          tag.dataType === 'stops' && tag.id === annotatedFilter.tagtype_id
      );
    }
  }
  return annotatedFilter;
};

const getDateRangeTitle = (
  dateRange: DateRange | RelativeDateRange,
  timeRanges: { id: string; short_name: string }[],
  useShortFormat = false,
  max?: string,
  timezone?: string
) => {
  const tz = timezone || moment.tz.guess();
  const format = useShortFormat ? 'MM/DD/YY' : 'MM/DD/YYYY';
  let startDate = moment(dateRange.startDate).tz(tz, true).format(format);
  let endDate = moment(dateRange.endDate).tz(tz, true).format(format);
  const isRelative = isRelativeDateRange(dateRange);

  if (isRelative && dateRange.dateRangeType !== RelativePeriod.CUSTOM) {
    const relativeDates = getDatesForRelativePeriod(
      dateRange.dateRangeType as RelativePeriod,
      max,
      format,
      timezone
    );
    startDate = relativeDates.startDate;
    endDate = relativeDates.endDate;
  }

  let days;
  let time = 'All day';
  const includeAtypicalDays = isRelative
    ? dateRange.includeAtypicalDays
    : dateRange.include_atypical_days;
  let holidays = includeAtypicalDays ? ' • Holidays' : '';
  const { weekdays, weekends } = getWeekdayWeekendState(dateRange.days);

  if ((dateRange.timeRange || '').length > 0 && timeRanges) {
    for (const element of timeRanges.values()) {
      if (element.id === dateRange.timeRange) {
        time = element.short_name;
        break;
      }
    }
  }
  if (weekdays === 1 && weekends === 1) {
    days = 'Everyday';
  } else if (weekdays === 0 && weekends === 1) {
    days = 'Weekends';
  } else if (weekdays === 1 && weekends === 0) {
    days = 'Weekdays';
  } else {
    days = [];
    const dow = ['M', 'Tu', 'W', 'Th', 'F', 'Sa', 'Su'];
    const fullDow: { [key: string]: string } = {
      M: 'Mondays',
      Tu: 'Tuesdays',
      W: 'Wednesdays',
      Th: 'Thursdays',
      F: 'Fridays',
      Sa: 'Saturdays',
      Su: 'Sundays',
    };
    for (const [index, element] of (dateRange.days ?? []).entries()) {
      if (element === 1) {
        days.push(dow[index]);
      }
    }
    if (days.length > 1) {
      days = days.toString();
    } else {
      days = fullDow[days[0]];
    }
  }

  return {
    dates: `${startDate} - ${endDate}`,
    times: `${days} • ${time}${holidays}`,
  };
};

const isTagFilter = (filter: Filter): filter is TagFilter =>
  filter.type === FilterType.TAG;

const getFilteredItems = <
  M extends BaseActivity,
  T extends {
    id: string;
    first?: M;
    second?: M;
    percent_change?: M;
  }
>(
  items: T[],
  category: FilterCategory,
  filters: Filter[]
) => {
  let filteredItems = [...items];
  let trueIds: string[] | undefined;
  let falseIds: string[] | undefined;
  let metricIds: string[] | undefined;

  if (filteredItems.length === 0 || filters.length === 0) {
    return filteredItems;
  }

  filters.forEach((filter) => {
    if (isTagFilter(filter)) {
      const tagIds = filter.tagtype?.taggedEntities;
      if (filter.bool === 'true') {
        if (trueIds === undefined) {
          trueIds = tagIds;
        } else {
          trueIds = trueIds.filter((id) => (tagIds || []).includes(id));
        }
      } else {
        if (falseIds === undefined) {
          falseIds = tagIds;
        } else {
          falseIds = falseIds.filter((id) => (tagIds || []).includes(id));
        }
      }
    } else {
      const metric = Number(filter.metric);
      const keyId = filter.metrictype.id;
      let filterIds: string[] = [];
      if (filter.inequality === 'greater than') {
        filterIds = items
          .filter((item) =>
            item.hasOwnProperty('second')
              ? (getCompositeActivityValue(item.percent_change, keyId) || 0) >
                metric
              : (getCompositeActivityValue(item.first, keyId) || 0) > metric
          )
          .map((data) => data.id);
      } else if (filter.inequality === 'less than') {
        filterIds = items
          .filter((item) =>
            item.hasOwnProperty('second')
              ? (getCompositeActivityValue(item.percent_change, keyId) || 0) <
                metric
              : (getCompositeActivityValue(item.first, keyId) || 0) < metric
          )
          .map((data) => data.id);
      } else if (filter.inequality === 'equal to') {
        filterIds = items
          .filter((item) =>
            item.hasOwnProperty('second')
              ? (getCompositeActivityValue(item.percent_change, keyId) || 0) ===
                metric
              : (getCompositeActivityValue(item.first, keyId) || 0) === metric
          )
          .map((data) => data.id);
      }
      metricIds =
        metricIds === undefined
          ? filterIds
          : metricIds.filter((item) => filterIds.includes(item));
    }
  });

  if (trueIds !== undefined) {
    filteredItems = filteredItems.filter((item) => trueIds!.includes(item.id));
  }
  if (falseIds !== undefined) {
    filteredItems = filteredItems.filter(
      (item) => !falseIds!.includes(item.id)
    );
  }
  if (metricIds !== undefined) {
    filteredItems = filteredItems.filter((item) =>
      metricIds!.includes(item.id)
    );
  }

  return filteredItems;
};

export {
  annotateFilterTagType,
  filterEquals,
  isTagFilter,
  getDateRangeTitle,
  getFilteredItems,
  getMetricDescription,
  getMetricTitle,
  removeFilterTagTypes,
};
