import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  logotype: {
    color: theme.palette.secondary.main,
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  appBar: {
    height: theme.spacing(6.5),
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.tertiary.main}`,
    zIndex: theme.zIndex.drawer + 1,
    justifyContent: 'center',
  },
  toolbar: {
    padding: theme.spacing(0, 1.5),
  },
  exitSettingsButton: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(1),
  },
}));
