import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  grid: {
    minHeight: '100vh',
    width: '100vw',
    backgroundColor: theme.palette.primary.main,
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  container: {
    backgroundColor: theme.palette.primary.main,
    display: 'table',
    margin: 16,
    height: 0,
  },
  logotypeImage: {
    width: 211,
    margin: 'auto',
  },
  passwordForm: {
    width: 400,
    height: 562,
    paddingTop: 32,
    paddingBottom: 32,
    paddingLeft: 20,
    paddingRight: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  errorMessage: {
    marginTop: 4,
    textAlign: 'center',
  },
  buttonContainer: {
    width: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(4),
    alignItems: 'center',
  },
  buttonStyle: {
    maxWidth: 360,
    textTransform: 'none',
    fontSize: 16,
    height: 50,
    padding: '4, 2, 4, 2',
  },
  tab: {
    minWidth: 450,
  },
  toggleButton: {
    width: '100%',
    textTransform: 'none',
    borderRadius: 10,
    textAlign: 'left',
  },
  icon: {
    marginRight: 8,
  },
}));
