import { Middleware } from 'redux';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import { enqueueToast } from '../../store/slices/layoutSlice';

const apiMiddleware: Middleware = (store) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    let message = 'Sorry, a network error occurred. Please try again.';
    if (action.payload?.data?.name) {
      message = action.payload.data.name;
    }

    store.dispatch(
      enqueueToast({
        severity: 'error',
        message,
      })
    );
  }

  return next(action);
};

export default apiMiddleware;
