import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Menu, Typography, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ReactComponent as ExpandIcon } from '../../assets/expand.svg';
import { ReactComponent as ExpandIconWhite } from '../../assets/expand_white.svg';
import HeaderMenuButton from '../HeaderMenuButton';
import {
  selectSecondDateRange,
  updateFirstDateRange,
  updateSecondDateRange,
} from '../../store/slices/analyticsFiltersSlice';
import { persistAnalyticsFilters } from '../../store/effects/analyticsFilters';
import { useLayoutState } from '../../context/LayoutContext';

const useStyles = makeStyles((theme) => ({
  expandMenuButtonActive: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  expandMenuButton: {
    backgroundColor: theme.palette.primary.main,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  headerMenu: {
    marginTop: theme.spacing(7),
  },
}));

export default function ExpandMenuButton() {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const layoutState = useLayoutState();
  const secondDateRange = useSelector(selectSecondDateRange);
  const classes = useStyles();

  const toggleExpandedData = () => {
    layoutState.setDataExpanded(!layoutState.dataExpanded);
    dispatch(updateFirstDateRange({ expanded: !layoutState.dataExpanded }));
    if (secondDateRange) {
      dispatch(updateSecondDateRange({ expanded: !layoutState.dataExpanded }));
    }
    dispatch(persistAnalyticsFilters());
  };

  return (
    <>
      <HeaderMenuButton
        aria-controls='expand-menu'
        aria-haspopup='true'
        aria-label='Expanded data'
        className={
          layoutState.dataExpanded
            ? classes.expandMenuButtonActive
            : classes.expandMenuButton
        }
        color='inherit'
        id='expand_button'
        isActive={layoutState.dataExpanded}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        title='Expanded data'
      >
        {layoutState.dataExpanded ? <ExpandIconWhite /> : <ExpandIcon />}
      </HeaderMenuButton>
      <Menu
        id='expand-menu'
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        className={classes.headerMenu}
        disableAutoFocusItem
      >
        <Box display='flex'>
          <ExpandIcon aria-hidden focusable='false' style={{ margin: 8 }} />
          <div>
            <Typography fontSize={'1.125rem'} weight='bold'>
              Expanded data
            </Typography>
            <Typography fontSize={'0.8125rem'} weight='medium'>
              Fill in missing trips using averages from historical data
            </Typography>
          </div>
          <Switch
            checked={layoutState.dataExpanded}
            inputProps={{ 'aria-label': 'Toggle expanded data' }}
            onChange={toggleExpandedData}
          />
        </Box>
      </Menu>
    </>
  );
}
