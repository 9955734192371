import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import isEqual from 'react-fast-compare';

const history = createBrowserHistory();

export const PARAMS_TYPE_JSON = [
  'routeFilters',
  'stopFilters',
  'tripFilters',
  'firstDateRange',
  'secondDateRange',
];

export const PARAMS_TYPE_STRING = ['metric', 'sortType', 'sortDirection'];

const isJsonEqual = (json1?: string, json2?: string) => {
  try {
    const parsed1 = JSON.parse(json1 || '');
    const parsed2 = JSON.parse(json2 || '');
    return isEqual(parsed1, parsed2);
  } catch (e) {
    // invalid json, impossible to tell if they were equal
    return false;
  }
};

/**
 * Takes in a set of params to update in the search,
 * validates, and navigates to the new search.
 * Unless explicitly set to undefined, all properties from
 * the existing search will be copied over as well.
 */
export const updateSearch = ({
  path = history.location.pathname,
  params = {},
}: {
  path?: string;
  params?: { [key: string]: string | undefined };
}) => {
  const oldParams = queryString.parse(history.location.search, {
    arrayFormat: 'comma',
  });

  let shouldUpdate = false;

  // allow undefined values to unset a value rather than be compared
  const entriesToDelete = Object.entries(params).filter(
    ([_, value]) => value === undefined
  );
  entriesToDelete.forEach(([param]) => {
    delete params[param];
    if (param in oldParams) {
      delete oldParams[param];
      shouldUpdate = true;
    }
  });

  // if we navigate to a new path, we should always update
  let pathname = history.location.pathname;
  if (pathname !== path) {
    shouldUpdate = true;
    pathname = path;
  }

  if (!shouldUpdate) {
    for (const [param, value] of Object.entries(params)) {
      const oldValue = oldParams[param];

      if (PARAMS_TYPE_JSON.includes(param)) {
        shouldUpdate = !isJsonEqual(value, oldValue as string);
      } else {
        shouldUpdate = value !== oldValue;
      }

      // early exit if we need to update
      if (shouldUpdate) {
        break;
      }
    }
  }

  if (shouldUpdate) {
    const newParams = queryString.stringify(
      { ...oldParams, ...params },
      { arrayFormat: 'comma' }
    );
    history.push({
      pathname,
      search: `?${newParams}`,
    });
  }
};

export default history;
