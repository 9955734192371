import { Point, LineString, MultiPolygon } from 'geojson';

export interface User {
  first_name: string;
  last_name: string;
  username: string;
  user_id: string;
  intercom_hash: string;
  email: string;
  user_roles: UserRole[];
}

export interface TeamUser {
  id: string;
  email: string;
  name: string;
  role: UserRole;
}

export interface UserRole {
  id: string;
  name: string;
  description: string;
  agency: Agency;
  edit_team_members?: boolean;
  edit_tags?: boolean;
  edit_links?: boolean;
  export_data?: boolean;
}

export interface Agency {
  agency_time_zone: string;
  id: string;
  internal_name: string;
  name: string;
  latitude: number;
  longitude: number;
  data_start_date: string;
  data_end_date?: string;
  data_feed_days_trailing: number;
  display_distance_type: 'miles' | 'kilometers';
  distance_type: number;
  use_stop_code: boolean;
  show_offs?: boolean;
  show_direction?: boolean;
  show_load?: boolean;
  show_maxload?: boolean;
  show_total_load?: boolean;
  show_activity?: boolean;
  show_bprh?: boolean;
  show_pdt?: boolean;
  show_trips?: boolean;
  show_atypical_days?: boolean;
  show_expand?: boolean;
  show_insights?: boolean;
  is_cleanse_only?: boolean;
}

export interface BaseReference {
  data_type: 'GTFS' | 'APC';
  id: string;
  last_updated: string;
  updated_by: string | null;
}

export interface RouteReference extends BaseReference {
  route_short_name: string | null;
  route_long_name: string | null;
  route_id: string | null;
  master_route?: {
    route_id: string | null;
    route_short_name: string | null;
    route_long_name: string | null;
  };
}

export interface StopReference extends BaseReference {
  stop_name: string | null;
  stop_id: string | null;
  stop_code: string | null;
  master_stop?: {
    stop_id: string | null;
    stop_name: string | null;
    stop_code: string | null;
  };
}

export interface BaseReferenceV2 {
  id: string;
  dataType: 'GTFS' | 'APC';
  gtfsId: string;
  updatedBy: string | null;
  lastUpdated: string;
}

export interface RouteReferenceV2 extends BaseReferenceV2 {
  shortName: string | null;
  longName: string | null;
  masterRoute?: {
    gtfsId: string;
    shortName: string;
    longName: string;
  };
}

export interface StopReferenceV2 extends BaseReferenceV2 {
  stopName: string | null;
  stopCode: string | null;
  masterStop?: {
    gtfsId: string;
    stopName: string;
    stopCode: string;
  };
}

export interface BaseActivity {
  sumOns: number;
  sumOffs: number;
  sumActivity: number | { ons: number; offs: number; total: number };
  avgOns: number;
  avgOffs: number;
  avgActivity: number | { ons: number; offs: number; total: number };
}

export interface ActivityWithLoad extends BaseActivity {
  maxLoad: number;
  avgLoad: number;
  sumLoad: number;
  avgMaxLoad: number;
  avgPassengerDistanceTravelled: number;
  sumPassengerDistanceTravelled: number;
}

export interface ActivityWithLoadAndBPRH extends ActivityWithLoad {
  boardingsPerRevenueHour: number;
}

export interface ActivityWithLoadAndBPRHAndExpanded extends ActivityWithLoad {
  boardingsPerRevenueHour: number;
  totalTripCount: number;
  unexpandedTripCount: number;
}

export interface ActivityWithExpanded extends ActivityWithLoad {
  totalTripCount: number;
  unexpandedTripCount: number;
}

export interface StopSegmentActivity {
  maxLoad: number;
  avgLoad: number;
  totalTripCount: number;
  unexpandedTripCount: number;
}

export interface Comparison<T> {
  first?: T;
  second?: T;
  percent_change?: T;
}

export interface BoardingEvent {
  lat?: number;
  lon?: number;
  first?: Metrics;
}

export interface DayReport {
  name: string;
  activity: ActivityWithLoadAndBPRH;
}

export interface DateReport {
  startDate: string;
  endDate: string;
  activity: ActivityWithLoadAndBPRH;
}

export interface PeriodReport {
  name: string;
  activity: ActivityWithLoad;
}

export interface LayerGroup {
  id: string;
  name: string;
}

export interface Layer {
  id: string;
  name: string;
  multiPolygon: MultiPolygon;
  layerGroupId: string;
  layerGroupName: string;
}

export interface Route {
  id: string;
  route_short_name: string;
  route_long_name?: string;
  route_id: string;
  hidden?: boolean;
  non_gtfs?: boolean;
  first?: Metrics;
  second?: Metrics;
  percent_change?: Metrics;
  trips?: Trip[];
}

export interface RouteV2 {
  id: string;
  gtfsId: string;
  shortName: string;
  nonGtfs: boolean;
}

export interface RouteSummary extends RouteV2 {
  activity: ActivityWithLoadAndBPRHAndExpanded;
}

export interface RouteDetail {
  id: string;
  gtfsId: string;
  shortName: string;
  nonGtfs: boolean;
  trips: TripShape[];
  stops: StopV2[];
}

export interface NonGTFSRoute {
  route: Route;
  boarding_events: BoardingEvent[];
}

export interface RouteV1 {
  id: string;
  nonGtfs: boolean;
  routeId: string;
  routeShortName: string;
  routeLongName: string;
}

export interface RouteSegment {
  direction_id: number;
  first?: Metrics;
  following_stop_id: string;
  following_stop_name: string;
  following_stop_point?: string | Point;
  id: string;
  percent_change?: Metrics;
  second?: Metrics;
  stop_code: string;
  stop_id: string;
  stop_name: string;
  stop_point?: string | Point;
  stop_segment?: LineString;
}

export interface RouteSegmentV2 {
  id: string;
  followingId: string | null;
  gtfsId: string;
  stopName: string;
  stopCode: string | null;
  stopSegment: LineString;
  direction: number;
  followingGtfsId: string | null;
  followingStopCode: string | null;
  followingStopName: string | null;
}

export interface StopSegmentLoad {
  id: string;
  followingId: string | null;
  gtfsId: string;
  stopCode: string | null;
  activity: StopSegmentActivity;
}

export interface Stop {
  id: string;
  stop_name: string;
  stop_code: string;
  stop_id: string;
  first?: Metrics;
  second?: Metrics;
  percent_change?: Metrics;
  stop_point?: string | Point;
  routes?: Route[];
}

export interface StopV1 {
  id: string;
  stopCode: string;
  stopName: string;
  stopId: string;
}

export interface StopV2 {
  id: string;
  gtfsId: string;
  stopCode: string | null;
  stopName: string;
  stopPoint: Point | string;
  activity: ActivityWithExpanded | BaseActivity;
}

export interface StopDetail {
  route: RouteV2;
  activity: ActivityWithLoad;
}

export interface Load {
  stops: { [key: string]: Stop };
  segments: RouteSegment[];
}

export interface Trip {
  id: string;
  direction_id: string;
  linestring: string;
  shape__gtfs_shape_id: string;
  trip_headsign: string;
  trip_id: string;
}

export interface TripV1 {
  id: string;
  routeId: string;
  routeShortName: string | null;
  variant: string;
  direction: number;
  startTime: string;
  endTime: string;
  distanceTraveled: number;
}

export interface TripV2 {
  id: string;
  direction: number | null;
  startTime: string;
  departureTimeNextDay: boolean;
  variant: string;
  activity?: ActivityWithLoad;
}

export interface TripDetail {
  trip: TripV2;
  stops: StopV2[];
}

export interface TripShape {
  lineString: LineString;
  routeShortName: string;
}

export interface RouteTag {
  id: string;
  name: string;
  agency: string;
  creator: string;
  route_id: string;
  route_short_name: string;
  days_since_creation: string;
  tagtype?: TagType;
}

export interface StopTag {
  id: string;
  name: string;
  agency: string;
  creator: string;
  stop_id: string;
  stop_name: string;
  days_since_creation: string;
  tagtype?: TagType;
}

export interface TagType {
  id: string;
  name: string;
  agency: string;
  creator: string;
  created_date: string;
  days_since_creation: string;
  data_type: 'stops' | 'routes';
  stop_ids?: string[];
  route_ids?: string[];
}

export type TagDataType = 'routes' | 'stops' | 'layers';

export interface Tag {
  id: string;
  objectId: string;
  objectName: string;
  creator: string;
  createdDate: string;
}

export interface TagTypeV2 {
  id: string;
  name: string;
  creator: string;
  createdDate: string;
  dataType: TagDataType;
  tagCount?: number;
  taggedEntities?: string[];
}

export interface TimeRange {
  id: string;
  str: string;
  short_name: string;
  long_name: string;
}

export interface TripVariant {
  id: string;
  direction: number;
  length: string;
  stopCount: number;
  tripCount: number;
  headsign: string | null;
}

export interface Vehicle {
  id: string;
  vehicleId: string;
  createdDate: string;
  updatedDate: string;
  incident: { id: string; status: IncidentState }[];
}

export enum MetricEntityType {
  AGENCY = 'agency',
  VEHICLE = 'vehicle',
}

export enum IncidentState {
  CLOSED = 'closed',
  ALARM = 'alarm',
  RECOVERED = 'recovered',
}

export interface MetricAlarm {
  id: string;
  name: string;
  displayName: string;
  entityType: MetricEntityType;
}

export interface MetricEntity {
  id: string;
  entityId: string;
  entityType: MetricEntityType;
  entityDisplayName: string;
}

export interface MetricAlarmIncident {
  id: string;
  state: IncidentState;
  firstSeenTs: string;
  lastSeenTs: string;
  muted: boolean;
  alarm: MetricAlarm;
  entity: MetricEntity;
}

export interface MetricAlarmRecordValue {
  name: string;
  displayName: string;
  value: number;
}

export interface MetricAlarmState extends MetricAlarm {
  state: 'OK' | 'ALARM' | 'INSUFFICIENT_DATA';
}

export interface MetricAlarmStateLog {
  alarms: MetricAlarmState[];
  entity: MetricEntity;
  evaluationDate: string;
  metrics: MetricAlarmRecordValue[];
}

export interface MetricAlert {
  id: string;
  creatorId: string;
  creatorName: string;
  creatorEmail: string;
  agencyId: string;
  name: string;
  diagnosticMetricAlarms: MetricAlarm[];
}

export interface MetricsV1 {
  avgOns: number;
  sumOns: number;
  avgOffs: number;
  sumOffs: number;
  avgActivity: number;
  sumActivity: number;
  avgLoad?: number;
  maxLoad?: number;
  avgMaxLoad?: number;
  sumLoad?: number;
  avgPassengerDistanceTravelled?: number;
  sumPassengerDistanceTravelled?: number;
  boardingsPerRevenueHour?: number;
}

export interface RedisStatus {
  status: 'started' | 'deferred' | 'queued' | 'finished';
  result: { data?: any };
}

export interface PaginationLink {
  href: string;
  rel: 'first' | 'self' | 'next' | 'status';
}

export interface PaginationMetadata {
  currentCursor: string | null;
  nextCursor?: string | null;
  totalCount?: number;
  limit?: number;
  offset?: number;
  links?: PaginationLink[];
}

export interface Notification {
  slug: string;
  actor: string | null;
  target_content_type: string | null;
  target_object_id: string | null;
  id: number;
  data?: { download_link?: string; link?: string };
  timestamp: string;
  unread: boolean;
  verb: string | null;
}

export interface UnreadNotifications {
  unread_count: number;
  unread_list: Notification[];
}

export interface FeatureFlag {
  feature_name: string;
  enabled: boolean;
}

export enum SubscriptionFrequency {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export enum SubscriptionEntityType {
  INSIGHT = 'insight',
  DIAGNOSTIC_METRIC_ALERT = 'diagnostic_metric_alert',
}

export interface Subscription {
  id: string;
  frequency: SubscriptionFrequency;
  dayOfWeek?: number;
  firstOfMonth?: boolean;
  lastOfMonth?: boolean;
  sendIfEmpty: boolean;
  creatorId: string;
  creatorName: string;
  entityId?: string;
  entityName?: string;
  entityType: SubscriptionEntityType;
  emails: string[];
}
