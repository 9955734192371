import {
  clearRouteFilters,
  clearStopFilters,
  clearTripFilters,
  selectSecondDateRange,
  selectAnalyticsFilters,
  selectRouteFilters,
  selectStopFilters,
  selectTripFilters,
  updateFirstDateRange,
  updateSecondDateRange,
  updateRouteFilters,
  updateStopFilters,
} from '../slices/analyticsFiltersSlice';
import { updateSearch } from '../../utils/history';
import {
  FilterCategory,
  annotateFilterTagType,
  removeFilterTagTypes,
} from '../../utils/filters';

function annotateFilterTagTypes(tagTypes) {
  return (dispatch, getState) => {
    const routeFilters = selectRouteFilters(getState());
    if (routeFilters && routeFilters.length > 0) {
      const taggedRouteFilters = routeFilters.map((filter) =>
        annotateFilterTagType(filter, FilterCategory.ROUTES, tagTypes)
      );
      dispatch(updateRouteFilters(taggedRouteFilters));
    }

    const stopFilters = selectStopFilters(getState());
    if (stopFilters && stopFilters.length > 0) {
      const taggedStopFilters = stopFilters.map((filter) =>
        annotateFilterTagType(filter, FilterCategory.STOPS, tagTypes)
      );
      dispatch(updateStopFilters(taggedStopFilters));
    }
  };
}

function persistAnalyticsFilters(path) {
  return (_, getState) => {
    const params = {
      routeFilters: undefined,
      stopFilters: undefined,
      tripFilters: undefined,
      firstDateRange: undefined,
      secondDateRange: undefined,
      metric: undefined,
      sortType: undefined,
      sortDirection: undefined,
    };

    const {
      routeFilters,
      stopFilters,
      tripFilters,
      firstDateRange,
      secondDateRange,
      metric,
      sortType,
      sortDirection,
    } = selectAnalyticsFilters(getState());

    if (metric) {
      params.metric = metric;
    }

    if (sortType) {
      params.sortType = sortType;
    }

    if (sortDirection) {
      params.sortDirection = sortDirection;
    }

    if (routeFilters && routeFilters.length > 0) {
      params.routeFilters = JSON.stringify(removeFilterTagTypes(routeFilters));
    }

    if (stopFilters && stopFilters.length > 0) {
      params.stopFilters = JSON.stringify(removeFilterTagTypes(stopFilters));
    }

    if (tripFilters && tripFilters.length > 0) {
      params.tripFilters = JSON.stringify(removeFilterTagTypes(tripFilters));
    }

    if (firstDateRange) {
      params.firstDateRange = JSON.stringify(firstDateRange);
    }

    if (secondDateRange) {
      params.secondDateRange = JSON.stringify(secondDateRange);
    }

    updateSearch({ path, params });
  };
}

function saveRouteFilters() {
  return (dispatch) => {
    dispatch(clearStopFilters());
    dispatch(clearTripFilters());
    dispatch(persistAnalyticsFilters('/routes'));
  };
}

function saveStopFilters() {
  return (dispatch) => {
    dispatch(clearRouteFilters());
    dispatch(clearTripFilters());
    dispatch(persistAnalyticsFilters('/stops'));
  };
}

function saveTripFilters() {
  return (dispatch, getState) => {
    const state = getState();
    const tripFilters = selectTripFilters(state);
    if (!tripFilters || tripFilters.length === 0) {
      dispatch(updateFirstDateRange({ tripIds: undefined }));
      const secondDateRange = selectSecondDateRange(state);
      if (secondDateRange) {
        dispatch(updateSecondDateRange({ tripIds: undefined }));
      }
    }
    dispatch(persistAnalyticsFilters('/routes/trips'));
  };
}

export {
  annotateFilterTagTypes,
  persistAnalyticsFilters,
  saveRouteFilters,
  saveStopFilters,
  saveTripFilters,
};
