import { Middleware } from 'redux';
import { STORE_NAME, getDB } from '../../utils/db';
import {
  addInsightTab,
  removeInsightTab,
} from '../../store/slices/layoutSlice';

const layoutMiddleware: Middleware = (store) => (next) => (action) => {
  const nextState = next(action);

  if (addInsightTab.match(action) || removeInsightTab.match(action)) {
    const state = store.getState();
    const rIC =
      typeof requestIdleCallback !== 'undefined'
        ? requestIdleCallback
        : (callback: Function) => {
            callback();
          };
    rIC(async () => {
      const db = await getDB();
      const agencyId = state.user.agency.agencyId;

      if (!db || !agencyId) {
        return;
      }

      const layoutTabs = state.layout.present.insights.activeTabs;
      const tx = db.transaction(STORE_NAME, 'readwrite');
      const store = await tx.objectStore(STORE_NAME);
      await Promise.all([store.put(layoutTabs, agencyId), tx.done]);
    });
  }

  return nextState;
};

export default layoutMiddleware;
