import React from 'react';
import { useHistory } from 'react-router-dom';
import HopthruLogo from '../HopthruLogo';
import { AppBar, Box, Divider, Toolbar, makeStyles } from '@material-ui/core';
import PrivacySelectorButton from '../PrivacySelectorButton';
import FavoriteButton from '../FavoriteButton';
import ChatButton from '../ChatButton';
import UserMenuButton from '../UserMenuButton';
import NotificationButton from '../NotificationButton';
import ViewModeToggleButton from '../ViewModeToggleButton';
import ViewName from '../ViewName';

const useStyles = makeStyles((theme) => ({
  appbar: {
    color: theme.palette.primary.main,
    backgroundColor: '#1B5A3C',
    height: theme.spacing(5),
    paddingTop: 0,
    paddingBottom: 0,
  },
  toolbar: {
    minHeight: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
    marginLeft: theme.spacing(2),
  },
  label: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: '1rem',
    marginLeft: theme.spacing(2),
  },
}));

export default function HeaderV2() {
  const classes = useStyles();
  const history = useHistory();

  const isSettings = history.location.pathname.includes('settings');

  return (
    <AppBar position='relative' className={classes.appbar}>
      <Toolbar className={classes.toolbar} disableGutters>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='flex-start'
          flexGrow={1}
          minWidth={0}
        >
          <HopthruLogo size={32} />
          <Divider
            orientation='vertical'
            flexItem
            className={classes.divider}
          />
          {isSettings && <Box className={classes.label}>Settings</Box>}
          {!isSettings && (
            <>
              <FavoriteButton />
              <PrivacySelectorButton />
              <Box mx={1} width='100%' minWidth={0}>
                <ViewName />
              </Box>
            </>
          )}
        </Box>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='flex-end'
          flexGrow={1}
        >
          {!isSettings && <ViewModeToggleButton />}
          <NotificationButton useV2 />
          <ChatButton useV2 />
          <UserMenuButton useV2 />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
