const VIEW_TYPE = {
  SYSTEM: 'system',
  ROUTES: 'routes',
  STOPS: 'stops',
  TRIPS: 'trips',
  ROUTE_DETAILS: 'route_details',
};

enum ViewType {
  SYSTEM = 'system',
  ROUTES = 'routes',
  STOPS = 'stops',
  TRIPS = 'trips',
  ROUTE_DETAILS = 'route_details',
  LAYERS = 'layers',
  STOP_DETAILS = 'stop_details',
  LAYER_DETAILS = 'layer_details',
  TRIP_DETAILS = 'trip_details',
}

const DEFAULT_ROUTES_LAYER = {
  id: ViewType.ROUTES,
  type: 'routes',
  name: 'Routes',
};

const DEFAULT_STOPS_LAYER = {
  id: ViewType.STOPS,
  type: 'stops',
  name: 'Stops',
};

const getValidMetricsForView = (
  viewType: ViewType,
  flags: Partial<FeatureFlags>
) => {
  const {
    showOffs,
    showLoad,
    showMaxload,
    showTotalLoad,
    showActivity,
    showBPRH,
    showPDT,
  } = flags;

  return {
    avgOns: true,
    sumOns: true,
    avgOffs: showOffs,
    sumOffs: showOffs,
    avgActivity: showActivity,
    sumActivity: showActivity,
    boardingsPerRevenueHour:
      showBPRH &&
      viewType !== ViewType.ROUTE_DETAILS &&
      viewType !== ViewType.STOPS &&
      viewType !== ViewType.TRIPS &&
      viewType !== ViewType.LAYERS,
    avgLoad: showLoad && viewType !== ViewType.STOPS,
    maxLoad: showMaxload && viewType !== ViewType.STOPS,
    avgMaxLoad: showMaxload && viewType !== ViewType.STOPS,
    sumLoad:
      showTotalLoad &&
      viewType !== ViewType.STOPS &&
      viewType !== ViewType.LAYERS,
    avgPassengerDistanceTravelled:
      showPDT &&
      viewType !== ViewType.STOPS &&
      viewType !== ViewType.TRIPS &&
      viewType !== ViewType.ROUTE_DETAILS,
    sumPassengerDistanceTravelled:
      showPDT &&
      viewType !== ViewType.STOPS &&
      viewType !== ViewType.TRIPS &&
      viewType !== ViewType.ROUTE_DETAILS,
  };
};

export {
  DEFAULT_ROUTES_LAYER,
  DEFAULT_STOPS_LAYER,
  VIEW_TYPE,
  ViewType,
  getValidMetricsForView,
};
