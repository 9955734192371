import React from 'react';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { Notification } from '../../store/types';
import MaybeWrap from '../MaybeWrap';

type NotificationCardProps = {
  notification: Notification;
};

const useStyles = makeStyles((theme) => ({
  link: {
    borderRadius: theme.spacing(1.5),
    display: 'block',
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    '&:hover': {
      outline: `1px solid ${theme.palette.secondary.main}`,
      textDecoration: 'underline',
    },
    '&:focus': {
      outline: `2px solid ${theme.palette.secondary.main}`,
      outlineOffset: 2,
      textDecoration: 'underline',
    },
  },
}));

const LinkWrapper = ({
  children,
  ...rest
}: { children?: React.ReactNode } & React.ComponentProps<'a'>) => (
  <a {...rest}>{children}</a>
);

export default function NotificationCard({
  notification,
}: NotificationCardProps) {
  const classes = useStyles();
  const { verb, data } = notification;
  const hasLink = !!data?.link;

  return (
    <MaybeWrap
      when={hasLink}
      wrapper={<LinkWrapper href={data?.link} className={classes.link} />}
    >
      <Box
        alignItems='center'
        borderRadius={10}
        border={1}
        display='flex'
        justifyContent='space-between'
        mb={1}
        padding={1.5}
        borderColor='tertiary.main'
      >
        {verb}
        {data?.download_link && (
          <Box ml={1}>
            <IconButton
              aria-label='Download file'
              color='secondary'
              component='a'
              href={data.download_link}
              size='small'
              target='_blank'
              title='Download file'
            >
              <GetApp />
            </IconButton>
          </Box>
        )}
      </Box>
    </MaybeWrap>
  );
}
