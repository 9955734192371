import { openDB } from 'idb';

export const DB_NAME = 'db';
export const STORE_NAME = 'layout';
export const DB_VERSION = 1;

export const getDB = async () => {
  if (!('indexedDB' in window)) {
    return;
  }

  const db = await openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      db.createObjectStore(STORE_NAME);
    },
  });

  return db;
};
