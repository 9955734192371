import React from 'react';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListSubheader,
  makeStyles,
} from '@material-ui/core';
import { Insight, InsightType } from '../../types';
import { getNameForInsightType } from '../../../../utils/insights';

type InsightListTabPanelProps = {
  data: { [key in InsightType]?: Insight[] };
  value: number;
  index: number;
  onInsightClick: (insight: Insight) => void;
};

const useStyles = makeStyles((theme) => ({
  listRoot: {
    backgroundColor: theme.palette.primary.main,
    maxHeight: theme.spacing(50),
    overflow: 'auto',
  },
}));

export default function InsightListTabPanel({
  data,
  value,
  index,
  onInsightClick,
}: InsightListTabPanelProps) {
  const classes = useStyles();
  const isEmpty = Object.keys(data).length === 0;

  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`insight-list-tabpanel-${index}`}
      aria-labelledby={`insight-list-tab-${index}`}
    >
      {isEmpty && (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          height={80}
        >
          No results found
        </Box>
      )}
      {!isEmpty && (
        <List className={classes.listRoot} dense subheader={<li />}>
          {Object.entries(data).map(([insightType, insights], index) => {
            return (
              <Box bgcolor='inherit' component='li' key={insightType}>
                <Box bgcolor='inherit' component='ul' padding={0}>
                  <ListSubheader>
                    {getNameForInsightType(insightType as InsightType)}
                  </ListSubheader>
                  {insights.map((insight) => (
                    <ListItem
                      button
                      key={insight.id}
                      onClick={() => onInsightClick(insight)}
                    >
                      {insight.name}
                    </ListItem>
                  ))}
                  {index !== Object.keys(data).length - 1 && (
                    <Box py={1} px={2}>
                      <Divider />
                    </Box>
                  )}
                </Box>
              </Box>
            );
          })}
        </List>
      )}
    </Box>
  );
}
