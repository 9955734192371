import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectActiveInsight,
  selectInsightViewType,
  setInsightViewType,
} from '../../store/slices/layoutSlice';
import MaybeWrap from '../MaybeWrap';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Box, Tooltip, withStyles } from '@material-ui/core';
import { MapOutlined, TableChartOutlined } from '@material-ui/icons';
import { isSupportedMapView } from '../../utils/insights';

const TooltipWrapper = ({
  children,
  title,
}: {
  children?: React.ReactNode;
  title: string;
}) => (
  <Tooltip title={title}>
    <Box component='span' lineHeight={0}>
      {children}
    </Box>
  </Tooltip>
);

const ViewModeToggleGroup = withStyles((theme) => ({
  grouped: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    padding: theme.spacing(0, 1),
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: theme.palette.primary.dark,
      color: '#1B5A3C',
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      '&:hover, &.Mui-focusVisible': {
        backgroundColor: theme.palette.primary.dark,
        color: '#1B5A3C',
      },
    },
    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.5)',
    },
  },
}))(ToggleButtonGroup);

export default function ViewModeToggleButton() {
  const dispatch = useDispatch();
  const activeView = useSelector(selectActiveInsight);
  const viewType = useSelector(selectInsightViewType);

  const disableMapView = !isSupportedMapView(activeView);
  let disabledTooltip =
    'Map view is only available when grouping by the entire date range';
  switch (activeView?.insightType) {
    case 'system':
      disabledTooltip = 'Map view is not available for system insights';
      break;
    case 'trips':
      disabledTooltip = 'Map view is not available for trip insights';
      break;
  }

  return (
    <MaybeWrap
      when={disableMapView}
      wrapper={<TooltipWrapper title={disabledTooltip} />}
    >
      <ViewModeToggleGroup
        value={viewType}
        exclusive
        onChange={(_, newViewType) =>
          newViewType && dispatch(setInsightViewType(newViewType))
        }
        aria-label='View type'
      >
        <ToggleButton
          value='table'
          aria-label='Table'
          size='small'
          title='Table'
        >
          <TableChartOutlined />
        </ToggleButton>
        <ToggleButton
          value='map'
          aria-label='Map'
          disabled={disableMapView}
          size='small'
          title='Map'
        >
          <MapOutlined />
        </ToggleButton>
      </ViewModeToggleGroup>
    </MaybeWrap>
  );
}
