import tinycolor from 'tinycolor2';

const primary = '#ffffff';
const secondary = '#202124';
const tertiary = '#dfe1e5';
const warning = '#f23b2f';
const success = '#3CD4A0';
const info = '#202124';
const alternate = '#a0b6e4';

const lightenRate = 7.5;
const darkenRate = 15;

const theme = {
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary).lighten(lightenRate).toHexString(),
      dark: tinycolor(primary).darken(darkenRate).toHexString(),
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary).lighten(lightenRate).toHexString(),
      dark: tinycolor(secondary).darken(darkenRate).toHexString(),
      contrastText: '#FFFFFF',
    },
    tertiary: {
      main: tertiary,
      light: tinycolor(tertiary).lighten(lightenRate).toHexString(),
      dark: tinycolor(tertiary).darken(darkenRate).toHexString(),
    },
    warning: {
      main: warning,
      light: tinycolor(warning).lighten(lightenRate).toHexString(),
      dark: tinycolor(warning).darken(darkenRate).toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success).lighten(lightenRate).toHexString(),
      dark: tinycolor(success).darken(darkenRate).toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info).lighten(lightenRate).toHexString(),
      dark: tinycolor(info).darken(darkenRate).toHexString(),
    },
    alternate: {
      main: alternate,
      light: tinycolor(info).lighten(lightenRate).toHexString(),
      dark: tinycolor(info).darken(darkenRate).toHexString(),
    },
    text: {
      primary: '#0c0c0c',
      secondary: '#696969',
      hint: '#B9B9B9',
    },
    background: {
      default: '#FFFFFF',
      light: '#F3F5FF',
    },
  },
  customShadows: {
    widget:
      '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetDark:
      '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetWide:
      '0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiToggleButtonGroup: {
      grouped: {
        margin: 0,
        border: 'none',
        '&:not(:first-child)': {
          borderRadius: 10,
        },
        '&:first-child': {
          borderRadius: 10,
        },
      },
    },
    MuiSnackbarContent: {
      root: {
        borderRadius: 10,
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    MuiMenu: {
      list: {
        padding: '8px 0',
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '0.8125rem',
        margin: 0,
        paddingBottom: 8,
        paddingTop: 8,
        paddingLeft: 16,
        paddingRight: 16,
        '& .MuiCheckbox-root': {
          padding: 0,
          paddingRight: 16,
        },
      },
    },
    MuiSelect: {
      icon: {
        color: '#000000',
        height: 20,
        width: 20,
        top: 'inherit',
      },
      outlined: {
        '&:valid, &:hover, &:focus, &:active': {
          borderRadius: 10,
        },
      },
      root: {
        height: 'auto',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 10,
        textTransform: 'none',
        boxShadow: 'none',
        padding: '6px 16px',
        height: '32px',
        fontWeight: 400,
        '& $label': {
          fontSize: '0.8125rem',
          letterSpacing: 'normal',
          lineHeight: 1.23,
        },
        '&:focus': {
          backgroundColor: 'rgba(74, 74, 74, 0.05)',
        },
        '&.Mui-focusVisible': {
          outline: '2px solid #202124',
          outlineOffset: 2,
        },
      },
      contained: {
        boxShadow: 'none',
        '&:hover, &:active, &.Mui-focusVisible': {
          boxShadow: 'none',
        },
        '& $label': {
          fontWeight: 500,
        },
      },
      containedSecondary: {
        '&:focus, &:hover': {
          backgroundColor: '#323438',
        },
      },
      outlined: {
        padding: '5px 15px',
        '&$disabled': {
          color: 'rgba(0, 0, 0, 0.38)',
          borderColor: tertiary,
        },
      },
      sizeSmall: {
        padding: '5px 12px',
        '& .MuiSvgIcon-root': {
          height: 20,
          width: 20,
        },
      },
    },
    MuiIconButton: {
      root: {
        '&.Mui-focusVisible': {
          outline: '2px solid #202124',
          outlineOffset: 2,
        },
      },
    },
    MuiListSubheader: {
      root: {
        color: '#202124',
        padding: '8px 16px',
        lineHeight: 1,
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          fontWeight: 500,
        },
        '&:selected, &:focus': {
          backgroundColor: '#eff1f2',
        },
      },
      button: {
        '&:hover, &:focus': {
          backgroundColor: '#eff1f2',
        },
      },
      gutters: {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
    MuiListItemIcon: {
      root: {
        color: '#202124',
        minWidth: 36,
      },
    },
    MuiListItemText: {
      inset: {
        paddingLeft: 36,
      },
      primary: {
        lineHeight: 1,
        fontSize: '0.875rem',
      },
      secondary: {
        lineHeight: 1,
        fontSize: '0.875rem',
      },
      root: {
        marginBottom: 0,
        marginTop: 0,
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: 'white',
      },
    },
    MuiTableRow: {
      hover: {
        cursor: 'pointer',
      },
    },
    MuiTableCell: {
      root: {
        fontSize: '0.875rem',
        borderBottom: '1px solid #dadce0',
        lineHeight: 'normal',
        padding: 16,
        whiteSpace: 'nowrap',
      },
      head: {
        fontWeight: 500,
        color: '#202124',
        backgroundColor: '#eff1f2',
        lineHeight: 'normal',
        padding: '12px 16px',
        '&:first-child': {
          borderTopLeftRadius: '10px',
        },
        '&:last-child': {
          borderTopRightRadius: '10px',
        },
      },
      stickyHeader: {
        backgroundColor: '#eff1f2',
      },
    },
    MuiTab: {
      root: {
        fontWeight: 500,
        letterSpacing: 'normal',
        textTransform: 'none',
        minWidth: 80,
      },
    },
    MuiTabs: {
      root: {
        backgroundColor: primary,
        borderBottom: '1px solid #DADCE0',
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: 10,
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderRadius: 10,
      },
      marginDense: {
        height: 32,
      },
      root: {
        fontSize: '0.8125rem',
        position: 'relative',
        '& $notchedOutline': {
          borderColor: 'rgba(0, 0, 0, 0.23)',
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: secondary,
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
        },
        '&$focused $notchedOutline': {
          borderColor: secondary,
          borderWidth: 1,
        },
        '&$disabled': {
          '& $notchedOutline': {
            borderColor: tertiary,
          },
        },
      },
      input: {
        padding: 12,
      },
      inputMarginDense: {
        paddingTop: 8,
        paddingBottom: 8,
        paddingRight: 12,
        paddingLeft: 12,
      },
    },
    MuiInputAdornment: {
      marginDense: {
        '& .MuiSvgIcon-root': {
          height: 20,
          width: 20,
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: '0.875rem',
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiFormLabel: {
      root: {
        color: '#202124',
        '&$focused': {
          color: '#202124',
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: '#202124',
        '&$focused': {
          color: '#202124',
        },
      },
      outlined: {
        '&$shrink': {
          fontSize: '1rem',
          transform: 'translate(0px, -24px)',
        },
      },
      marginDense: {
        color: '#696969',
        '&$focused': {
          color: '#696969',
        },
        '&$outlined$shrink': {
          fontSize: '0.8125rem',
          transform: 'translate(16px, -21px)',
        },
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 10,
        minWidth: 400,
        padding: 16,
      },
    },
    MuiChip: {
      sizeSmall: {
        height: 20,
      },
      labelSmall: {
        fontSize: '0.6875rem',
        fontWeight: 500,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: secondary,
        borderRadius: 10,
        boxShadow: '0 1px 8px 0 rgba(0, 0, 0, 0.25)',
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        '&[class*="MuiOutlinedInput-root"]': {
          flexWrap: 'nowrap',
          padding: 12,
          '& $input, & $input:first-child': {
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
      option: {
        fontSize: '0.8125rem',
        paddingBottom: 8,
        paddingTop: 8,
        paddingLeft: 16,
        paddingRight: 16,
        '& .MuiCheckbox-root': {
          padding: 0,
          paddingRight: 16,
        },
      },
      loading: {
        fontSize: '0.8125rem',
      },
      noOptions: {
        fontSize: '0.8125rem',
      },
      paper: {
        borderRadius: 10,
      },
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
        height: 'auto',
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        fontSize: '3.75rem',
      },
    },
    MuiAlert: {
      root: {
        borderRadius: 10,
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$disabled': {
          color: tertiary,
        },
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: secondary,
        color: primary,
        '&:hover': {
          backgroundColor: secondary,
          opacity: 0.8,
        },
      },
    },
  },
};

export default theme;
