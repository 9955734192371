import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ListItemIcon,
  ListItemText,
  MenuList,
  MenuItem,
  Popover,
} from '@material-ui/core';
import { Check, Lock, Public } from '@material-ui/icons';
import HeaderMenuButtonV2 from '../HeaderMenuButton/HeaderMenuButtonV2';
import { selectUser } from '../../store/slices/userSlice';
import {
  selectActiveInsight,
  setActiveInsight,
} from '../../store/slices/layoutSlice';

export default function PrivacySelectorButton() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const user = useSelector(selectUser);
  const activeView = useSelector(selectActiveInsight);
  const isPublic = activeView?.public;

  const updatePrivacy = (isPublic: boolean) => {
    if (activeView) {
      dispatch(setActiveInsight({ ...activeView, public: isPublic }));
    }
  };

  return (
    <>
      <HeaderMenuButtonV2
        aria-controls='privacy-menu'
        aria-label='View privacy'
        aria-haspopup='true'
        className=''
        color='inherit'
        disabled={user.userId !== activeView?.creatorId}
        isActive={!!anchorEl}
        onClick={(e: React.MouseEvent) => setAnchorEl(e.currentTarget)}
        title='View privacy'
      >
        {isPublic ? <Public /> : <Lock />}
      </HeaderMenuButtonV2>
      <Popover
        id='privacy-menu'
        open={!!anchorEl}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        <MenuList>
          <MenuItem onClick={() => updatePrivacy(true)}>
            {isPublic && (
              <ListItemIcon>
                <Check style={{ width: 20, height: 20 }} />
              </ListItemIcon>
            )}
            <ListItemText inset={!isPublic} disableTypography>
              Public
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={() => updatePrivacy(false)}>
            {!isPublic && (
              <ListItemIcon>
                <Check style={{ width: 20, height: 20 }} />
              </ListItemIcon>
            )}
            <ListItemText inset={isPublic} disableTypography>
              Private
            </ListItemText>
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  );
}
