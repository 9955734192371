import { createSlice } from '@reduxjs/toolkit';
import queryString from 'query-string';
import { filterEmptyProperties, lossyDeepClone } from '../../utils/utils';
import history from '../../utils/history';
import { createDeepCompareSelector } from './selectors';
import { IncidentState } from '../types';

const PARAMS_TYPE = ['dateRange', 'statuses', 'eventTypes'];

function getParamState() {
  const newState: { [key: string]: any } = {};
  const params = queryString.parse(history.location.search, {
    arrayFormat: 'comma',
  });

  if (params) {
    for (let param of PARAMS_TYPE) {
      if (param in params) {
        try {
          newState[param] = JSON.parse(params[param] as string);
        } catch (e) {
          // do something, invalid json
        }
      }
    }
  }

  return newState;
}

export const initialState = getParamState();

export const settingsFiltersSlice = createSlice({
  name: 'settingsFilters',
  initialState,
  reducers: {
    replaceDateRange: (state, action) => {
      state.dateRange = action.payload;
    },
    updateDateRange: (state, action) => {
      // Avoid setting date range to an empty object by only setting if
      // date range already exists and changeset is not all empty (unset).

      const changeset = filterEmptyProperties(action.payload);
      if (!state.dateRange && Object.keys(changeset).length > 0) {
        state.dateRange = lossyDeepClone({
          ...state.dateRange,
          ...changeset,
        });
      } else if (state.dateRange) {
        state.dateRange = lossyDeepClone({
          ...state.firstDateRange,
          ...action.payload,
        });
      }
    },
    updateStatusFilters: (state, action) => {
      state.statuses = action.payload;
    },
    updateEventTypeFilters: (state, action) => {
      state.eventTypes = action.payload;
    },
    clearDateRange: (state) => {
      state.dateRange = undefined;
    },
    clearStatusFilters: (state) => {
      state.statuses = undefined;
    },
    clearEventTypeFilters: (state) => {
      state.eventTypes = undefined;
    },
    clearFilters: () => ({}),
  },
});

export const {
  clearDateRange,
  clearEventTypeFilters,
  clearFilters,
  clearStatusFilters,
  replaceDateRange,
  updateDateRange,
  updateEventTypeFilters,
  updateStatusFilters,
} = settingsFiltersSlice.actions;

export const selectSettingsFilters = (state: any) => state.settingsFilters;
export const selectDateRange = createDeepCompareSelector(
  (state: any) => state.settingsFilters.dateRange,
  (dateRange: RelativeDateRange) => dateRange
);
export const selectStatusFilters = createDeepCompareSelector(
  (state: any) => state.settingsFilters.statuses,
  (statuses: IncidentState[]) => statuses
);
export const selectEventTypeFilters = createDeepCompareSelector(
  (state: any) => state.settingsFilters.eventTypes,
  (eventTypes: string[]) => eventTypes
);

export default settingsFiltersSlice.reducer;
