import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { withStyles } from '@material-ui/styles';
import history from '../../utils/history';
import { selectAgency } from '../../store/slices/userSlice';
import {
  selectSecondDateRange,
  updateFirstDateRange,
  updateSecondDateRange,
} from '../../store/slices/analyticsFiltersSlice';
import { persistAnalyticsFilters } from '../../store/effects/analyticsFilters';
import { postEvent } from '../../utils/api';

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    border: 'none',
    color: theme.palette.text.secondary,
    fontSize: '1rem',
    letterSpacing: 'normal',
    lineHeight: '1rem',
    margin: theme.spacing(1, 2),
    padding: theme.spacing(1, 1.5),
    textTransform: 'none',
    '&:first-child': {
      borderRadius: theme.spacing(1.25),
    },
    '&:not(:first-child)': {
      borderRadius: theme.spacing(1.25),
    },
    '&:focus, &:hover': {
      color: theme.palette.text.secondary,
      backgroundColor: 'rgba(74, 74, 74, 0.05)',
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
      '&:focus, &:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
    '&.Mui-focusVisible': {
      outline: `2px solid ${theme.palette.secondary.main}`,
      outlineOffset: 2,
    },
  },
}))(ToggleButtonGroup);

export default function ViewToggle() {
  const dispatch = useDispatch();
  const secondDateRange = useSelector(selectSecondDateRange);
  const { showInsights } = useSelector(selectAgency);

  const getPath = useCallback((path) => {
    let value;
    let event;
    switch (path) {
      case path.match(/\/(routes|trips)/)?.input:
        value = '/routes';
        event = 'routes_tab_clicked';
        break;
      case path.match(/\/stops/)?.input:
        value = '/stops';
        event = 'stops_tab_clicked';
        break;
      case path.match(/\/insights/)?.input:
        value = '/insights';
        event = 'insights_tab_clicked';
        break;
      case '/system':
      default:
        value = '/system';
        event = 'system_tab_clicked';
        break;
    }
    return { value, event };
  }, []);

  const onViewToggle = useCallback(
    (_, viewPath) => {
      if (viewPath) {
        const { event } = getPath(viewPath);
        postEvent(event);

        // wipe all id filters before navigating to a new tab
        const changeset = {
          routeIds: undefined,
          stopIds: undefined,
          tripIds: undefined,
          variant: undefined,
          direction: undefined,
        };

        dispatch(updateFirstDateRange(changeset));
        if (secondDateRange) {
          dispatch(updateSecondDateRange(changeset));
        }
        dispatch(persistAnalyticsFilters(viewPath));
      }
    },
    [dispatch, getPath, secondDateRange]
  );

  const { value } = getPath(history.location.pathname);

  return (
    <StyledToggleButtonGroup
      aria-label='Active view'
      exclusive
      onChange={onViewToggle}
      size='small'
      value={value}
    >
      <ToggleButton value='/system'>Full system</ToggleButton>
      <ToggleButton value='/routes'>Routes</ToggleButton>
      <ToggleButton value='/stops'>Stops</ToggleButton>
      {showInsights && <ToggleButton value='/insights'>Insights</ToggleButton>}
    </StyledToggleButtonGroup>
  );
}
