import React, { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';
import { selectAgency } from '../../store/slices/userSlice';
import { useGetFeaturesQuery } from '../../store/slices/apiSlice';
import { FeatureFlag } from '../../store/types';

const FeatureContext = createContext<{
  userId?: string;
  agencyId?: string;
  internalName?: string;
  features?: FeatureFlag[];
}>({});

export default function FeatureProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const { agencyId } = useSelector(selectAgency);
  const { data: features } = useGetFeaturesQuery(
    { agencyId },
    { skip: !agencyId }
  );
  return (
    <FeatureContext.Provider value={{ features }}>
      {children}
    </FeatureContext.Provider>
  );
}

export const useFeatureEnabled = (...featureNames: string[]) => {
  const { features } = useContext(FeatureContext);
  const matchingFeatures = features?.filter(({ feature_name }) =>
    featureNames.includes(feature_name)
  );
  if (!matchingFeatures || matchingFeatures.length !== featureNames.length) {
    return false;
  }
  return matchingFeatures.every((feature) => feature.enabled);
};

export const KNOWN_FEATURES = {
  INCLUDE_EXPANSION_COVERAGE_SCORES: 'include-expansion-coverage-scores',
  SHOW_DIAGNOSTIC_REPORTING: 'show_diagnostic_reporting',
  SHOW_DIAGNOSTIC_REPORTING_ALERTS: 'show_diagnostic_reporting_alerts',
  SHOW_LAYERS: 'show_layers',
  SHOW_LAYERS_INSIGHTS: 'show_layers_insights',
  SHOW_MERGE_REDESIGN: 'show_merge_redesign',
  DUAL_DATE_RANGE_INSIGHTS: 'show_dual_date_range_insights',
  SHOW_FEATURE_SCALING: 'show_feature_scaling',
};
