import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { ReactComponent as LogoSecondary } from '../../assets/hopthru_logo_secondary.svg';

const useStyles = makeStyles({
  logoContainer: ({ size }) => ({
    height: size,
    width: size,
    flexShrink: 0
  }),
});

export default function HopthruLogo({ size = 44 }) {
  const classes = useStyles({ size });

  return (
    <Link
      to='/'
      title='Hopthru'
      aria-label='Hopthru'
      className={classes.logoContainer}
    >
      <LogoSecondary viewBox='0 0 190 190' height='100%' width='100%' />
    </Link>
  );
}
