import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import ViewToggle from '../ViewToggle';
import HopthruLogo from '../HopthruLogo';
import UserMenuButton from '../UserMenuButton';
import ExpandMenuButton from '../ExpandMenuButton';
import ChatButton from '../ChatButton';
import NotificationButton from '../NotificationButton';

// styles
import useStyles from './styles';

// context
import { useSelector } from 'react-redux';
import { selectAgency } from '../../store/slices/userSlice';

export default function Header() {
  const history = useHistory();
  const classes = useStyles();

  // global
  const { isCleanseOnly, showExpand } = useSelector(selectAgency);

  let page = 'analyze';
  if (history.location.pathname.includes('settings')) {
    page = 'settings';
  } else if (history.location.pathname.includes('insights')) {
    page = 'insights';
  }

  const leftHandItems = [];
  if (page === 'settings') {
    if (!isCleanseOnly) {
      leftHandItems.push(
        <IconButton
          aria-label='Exit settings'
          className={classes.exitSettingsButton}
          component={Link}
          key='exit-settings-icon-button'
          title='Exit settings'
          to='/'
          size='small'
        >
          <ArrowBack />
        </IconButton>
      );
    }
    leftHandItems.push(
      <Typography
        key='settings-title-label'
        variant='h6'
        className={classes.logotype}
      >
        Settings
      </Typography>
    );
  } else {
    leftHandItems.push(<HopthruLogo key='hopthru-main-logo' />);
  }

  const rightHandItems = [];
  if (showExpand && page === 'analyze') {
    rightHandItems.push(<ExpandMenuButton key='expanded-data-toggle-button' />);
  }
  rightHandItems.push(
    <NotificationButton key='notification-menu-toggle-button' />,
    <ChatButton key='intercom-chat-toggle-button' />,
    <UserMenuButton key='user-menu-toggle-button' />
  );

  return (
    <AppBar position='fixed' className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='flex-start'
          flexGrow={1}
          flexBasis={0}
        >
          {leftHandItems}
        </Box>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          flexGrow={3}
          flexBasis={0}
        >
          {page !== 'settings' && <ViewToggle />}
        </Box>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='flex-end'
          flexGrow={1}
          flexBasis={0}
        >
          {rightHandItems}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
