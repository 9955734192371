import { getAgencyId } from './storeAware';

import {
  sleep,
  clearSessionStorage,
  checkCache,
  cacheResult,
  combineRoutesLoadResult,
} from './utils';

const queryString = require('query-string');

async function executeFetch(
  url,
  method,
  body = undefined,
  setError = undefined,
  useCache = false
) {
  if (setError) setError(null);
  if (body) body = JSON.stringify(body);
  const cacheKey = url + body;
  const cachedHits = checkCache(cacheKey);
  if (useCache && cachedHits) {
    return cachedHits;
  } else {
    const access_token = localStorage.getItem('access_token');
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + access_token,
    };
    const requestOptions = {
      method: method,
      headers: headers,
    };
    if (body) requestOptions.body = body;
    try {
      let response = await fetch(
        process.env.REACT_APP_BACKEND_URL + url,
        requestOptions
      );
      let data = await response.json();
      if (response.ok) {
        if (useCache) {
          try {
            cacheResult(cacheKey, data);
          } catch (e) {
            clearSessionStorage();
          }
        }
        return data;
      } else {
        if (setError) {
          if (data.message) setError(data.message);
          else setError(response.statusText);
        }
      }
    } catch (error) {
      if (setError) setError(error);
    }
  }
}

function cleanDateRange(dateRange) {
  delete dateRange.weekdays;
  delete dateRange.weekends;
  for (const [key, value] of Object.entries(dateRange)) {
    if (dateRange[key].length === 0) {
      delete dateRange[key];
    }
  }
  return dateRange;
}

async function fetchRedisResult(redisId, setError = undefined) {
  const cacheHits = checkCache(redisId);
  if (cacheHits) {
    return cacheHits;
  }

  const access_token = localStorage.getItem('access_token');
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + access_token,
  };
  const requestOptions = {
    method: 'GET',
    headers: headers,
  };

  let url = '/redis/status/' + redisId;

  async function getResult() {
    let response = await fetch(
      process.env.REACT_APP_BACKEND_URL + url,
      requestOptions
    );
    let data = await response.json();
    if (response.ok) {
      return data;
    } else {
      if (setError) {
        if (data.message) setError(data.message);
        else setError(response.statusText);
      }
      return { status: 'failed' };
    }
  }

  // eslint-disable-next-line no-constant-condition
  while (true) {
    let result = await getResult();
    if (result.status == 'finished') {
      cacheResult(redisId, result.result);
      return result.result;
    } else if (['queued', 'started', 'deferred'].includes(result.status)) {
      await sleep(2000);
    } else {
      // job failed
      if (setError) setError('Job failed');
      return;
    }
  }
}

// This endpoint only supports a single date range
async function fetchNonRoutedDetails(history, routeId, setError = null) {
  const params = queryString.parse(history.location.search);
  const firstDateRange = cleanDateRange(JSON.parse(params.firstDateRange));
  let body = {
    agency_id: getAgencyId(),
    firstDateRange: firstDateRange,
  };
  let url = '/boardings/non_gtfs_routes/' + routeId;
  let data = await executeFetch(url, 'POST', body, setError, true);
  return data;
}

async function fetchRouteLoad(history, setError = null) {
  const params = queryString.parse(history.location.search);
  let url = '/boardings/routes/load';

  let result;
  const firstDateRangeBody = {
    agency_id: getAgencyId(),
    firstDateRange: params.firstDateRange,
  };
  const firstDateRangeRQResult = executeFetch(
    url,
    'POST',
    firstDateRangeBody,
    setError,
    true
  );
  if (params.secondDateRange) {
    const secondDateRangeBody = {
      agency_id: getAgencyId(),
      firstDateRange: params.secondDateRange,
    };
    const secondDateRangeRQResult = executeFetch(
      url,
      'POST',
      secondDateRangeBody,
      setError,
      true
    );
    const rqResults = [
      await firstDateRangeRQResult,
      await secondDateRangeRQResult,
    ];
    const firstDateRangeResult = fetchRedisResult(
      rqResults[0].redis_id,
      setError
    );
    const secondDateRangeResult = fetchRedisResult(
      rqResults[1].redis_id,
      setError
    );
    const response = [await firstDateRangeResult, await secondDateRangeResult];
    result = combineRoutesLoadResult(response[0], response[1]);
  } else {
    let response = await firstDateRangeRQResult;
    result = await fetchRedisResult(response.redis_id, setError);
  }
  return result;
}

async function fetchRouteVariants(history, routeId, setError = null) {
  const params = queryString.parse(history.location.search);
  let body = {
    agency_id: getAgencyId(),
    firstDateRange: cleanDateRange(JSON.parse(params.firstDateRange)),
  };

  if (params.secondDateRange) {
    body.secondDateRange = cleanDateRange(JSON.parse(params.secondDateRange));
  }
  let url = '/boardings/routes/' + routeId + '/trip_variants';
  let data = await executeFetch(url, 'POST', body, setError, true);
  return data;
}

async function fetchStopDescription(history, setError = null) {
  const params = queryString.parse(history.location.search);
  let body = {
    agency_id: getAgencyId(),
    firstDateRange: params.firstDateRange,
    secondDateRange: params.secondDateRange,
  };
  let url = '/boardings/stops/description';
  let data = await executeFetch(url, 'POST', body, setError, true);
  return data;
}

async function fetchTimeRanges(history, setError = null) {
  let body = {
    agency_id: getAgencyId(),
  };
  let url = '/agencies/timeranges';
  let data = await executeFetch(url, 'POST', body, setError, true);
  return data;
}

async function createMasterReference(
  history,
  reference_id,
  reference_type,
  setError = null
) {
  let body = {
    agency_id: getAgencyId(),
    reference_id: reference_id,
    reference_type: reference_type,
    new_master: true,
  };
  let url = '/agencies/references/link';
  let data = await executeFetch(url, 'POST', body, setError);
  clearSessionStorage();
  return data;
}

async function createDataLink(
  history,
  reference_id,
  reference_type,
  master_id,
  setError = null
) {
  let body = {
    agency_id: getAgencyId(),
    reference_id: reference_id,
    reference_type: reference_type,
    master_id: master_id,
  };
  let url = '/agencies/references/link';
  let data = await executeFetch(url, 'POST', body, setError);
  clearSessionStorage();
  return data;
}

async function destroyDataLink(
  history,
  reference_id,
  reference_type,
  setError = null
) {
  let body = {
    agency_id: getAgencyId(),
    reference_id: reference_id,
    reference_type: reference_type,
  };
  let url = '/agencies/references/unlink';
  let data = await executeFetch(url, 'POST', body, setError);
  clearSessionStorage();
  return data;
}

async function fetchMasterRoutes(history, setError = null) {
  let body = {
    agency_id: getAgencyId(),
  };
  let url = '/agencies/master/routes';
  let data = await executeFetch(url, 'POST', body, setError);
  return data;
}

async function fetchMasterRoute(history, routeId, setError = null) {
  let url = '/agencies/master/routes/' + routeId;
  let data = await executeFetch(url, 'GET', null, setError);
  return data;
}

async function fetchMasterStops(history, setError = null) {
  let body = {
    agency_id: getAgencyId(),
  };
  let url = '/agencies/master/stops';
  let data = await executeFetch(url, 'POST', body, setError);
  return data;
}

async function fetchMasterStop(history, stopId, setError = null) {
  let url = '/agencies/master/stops/' + stopId;
  let data = await executeFetch(url, 'GET', null, (setError = setError));
  return data;
}

async function fetchHolidays(history, setError = null) {
  let url = '/agencies/' + getAgencyId() + '/atypical-days';
  let data = await executeFetch(url, 'GET', null, setError);
  return data;
}

async function postHoliday(history, date, dow, setError = null) {
  let body = {
    service_date: date,
    effective_day_of_week: dow,
  };
  let url = '/agencies/' + getAgencyId() + '/atypical-days';
  let data = await executeFetch(url, 'POST', body, setError);
  return data;
}

async function updateHoliday(history, holidayId, date, dow, setError = null) {
  let body = {
    service_date: date,
    effective_day_of_week: dow,
  };
  let url = '/agencies/' + getAgencyId() + '/atypical-days/' + holidayId;
  let data = await executeFetch(url, 'PUT', body, setError);
  return data;
}

async function deleteHoliday(history, holidayId, date, dow, setError = null) {
  let url = '/agencies/' + getAgencyId() + '/atypical-days/' + holidayId;
  let data = await executeFetch(url, 'DELETE', null, setError);
  return data;
}

async function createRouteTags(history, routeId, tagTypeIds, setError = null) {
  let body = {
    agency_id: getAgencyId(),
    tagtype_ids: tagTypeIds,
  };
  let url = '/agencies/master/routes/' + routeId + '/tags';
  let data = await executeFetch(url, 'POST', body, setError);
  clearSessionStorage();
  return data;
}

async function createStopTags(history, stopId, tagTypeIds, setError = null) {
  let body = {
    agency_id: getAgencyId(),
    tagtype_ids: tagTypeIds,
  };
  let url = '/agencies/master/stops/' + stopId + '/tags';
  let data = await executeFetch(url, 'POST', body, setError);
  clearSessionStorage();
  return data;
}

async function fetchUsers(history, setError = null) {
  let body = {
    agency_id: getAgencyId(),
  };
  let url = '/users';
  let data = await executeFetch(url, 'POST', body, setError);
  return data;
}

async function fetchRoles(history, setError = null) {
  let body = {
    agency_id: getAgencyId(),
  };
  let url = '/roles';
  let data = await executeFetch(url, 'POST', body, setError);
  return data;
}

async function editUserRole(history, userId, roleId, setError = null) {
  let body = {
    role_id: roleId,
    agency_id: getAgencyId(),
  };
  let url = '/users/' + userId + '/role';
  let data = await executeFetch(url, 'POST', body, setError);
  return data;
}

async function deleteUser(history, userId, setError = null) {
  let url = '/users/' + userId + '/delete';
  let body = {
    agency_id: getAgencyId(),
  };
  let data = await executeFetch(url, 'POST', body, setError);
  return data;
}

async function resetPassword(
  history,
  currentPassword,
  newPassword,
  setError = null
) {
  let body = {
    current_password: currentPassword,
    new_password: newPassword,
  };
  let url = '/me/reset_password';
  let data = await executeFetch(url, 'POST', body, setError);
  return data;
}

async function fetchDepartments(history, setError = null) {
  let url = '/departments';
  let data = await executeFetch(url, 'GET', null, setError);
  return data;
}

async function completeSignup(
  history,
  firstName,
  lastName,
  jobTitle,
  departments,
  setError = null
) {
  let url = '/me/complete_signup';
  let body = {
    first_name: firstName,
    last_name: lastName,
    job_title: jobTitle,
    departments: departments,
  };
  let data = await executeFetch(url, 'POST', body, setError);
  return data;
}

async function submitSystemExport(params, setError = null) {
  const body = {
    agency_id: getAgencyId(),
    start_date: params.startDate,
    end_date: params.endDate,
    days: params.days,
    time_range: params.timeRange,
    group_by: params.groupBy,
    include_totals: params.includeTotals,
    include_averages: params.includeAverages,
    expanded: params.expanded,
    include_atypical_days: params.include_atypical_days,
  };
  let url = '/exports/system';
  let data = await executeFetch(url, 'POST', body, setError, false);
  return data;
}

async function submitRoutesExport(params, setError = null) {
  const body = {
    agency_id: getAgencyId(),
    start_date: params.startDate,
    end_date: params.endDate,
    days: params.days,
    time_range: params.timeRange,
    group_by: params.groupBy,
    include_totals: params.includeTotals,
    include_averages: params.includeAverages,
    expanded: params.expanded,
    route_ids: params.selectedIds,
    include_atypical_days: params.include_atypical_days,
  };
  let url = '/exports/routes';
  let data = await executeFetch(url, 'POST', body, setError, false);
  return data;
}

async function submitStopsExport(params, setError = null) {
  const body = {
    agency_id: getAgencyId(),
    start_date: params.startDate,
    end_date: params.endDate,
    days: params.days,
    time_range: params.timeRange,
    group_by: params.groupBy,
    include_totals: params.includeTotals,
    include_averages: params.includeAverages,
    expanded: params.expanded,
    stop_ids: params.selectedIds,
    include_atypical_days: params.include_atypical_days,
  };
  let url = '/exports/stops';
  let data = await executeFetch(url, 'POST', body, setError, false);
  return data;
}

async function submitRouteDetailsExport(params, setError = null) {
  const body = {
    agency_id: getAgencyId(),
    start_date: params.startDate,
    end_date: params.endDate,
    days: params.days,
    time_range: params.timeRange,
    group_by: params.groupBy,
    include_totals: params.includeTotals,
    include_averages: params.includeAverages,
    expanded: params.expanded,
    route_ids: params.routeIds,
    include_atypical_days: params.include_atypical_days,
  };
  let url = '/exports/route_details';
  let data = await executeFetch(url, 'POST', body, setError, false);
  return data;
}

async function submitTagExport(params, setError = null) {
  let url = '/exports/tags/' + params.tagtypeId;
  const body = {
    agency_id: getAgencyId(),
  };
  let data = await executeFetch(url, 'POST', body, setError, false);
  return data;
}

async function postEvent(name, properties = {}) {
  let body = {
    name: name,
    properties: properties,
  };
  let agencyId = getAgencyId();
  if (agencyId !== null) {
    body.agency_id = agencyId;
  }
  let url = '/track_event';
  let data = await executeFetch(url, 'POST', body);
  return data;
}

export {
  fetchNonRoutedDetails,
  fetchRouteLoad,
  fetchRouteVariants,
  fetchTimeRanges,
  fetchStopDescription,
  fetchMasterRoutes,
  fetchMasterStops,
  fetchHolidays,
  postHoliday,
  updateHoliday,
  deleteHoliday,
  createMasterReference,
  createDataLink,
  destroyDataLink,
  createRouteTags,
  createStopTags,
  fetchMasterRoute,
  fetchMasterStop,
  postEvent,
  resetPassword,
  fetchUsers,
  fetchDepartments,
  completeSignup,
  fetchRoles,
  editUserRole,
  deleteUser,
  submitSystemExport,
  submitRoutesExport,
  submitStopsExport,
  submitRouteDetailsExport,
  submitTagExport,
};
