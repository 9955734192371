import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query/react';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Grid,
  FormControlLabel,
  IconButton,
  Typography,
} from '@material-ui/core';
import { ChevronRight, KeyboardArrowDown } from '@material-ui/icons';
import logo from '../../assets/hopthru.svg';
import {
  useGetSubscriptionsQuery,
  useDeleteSubscriptionsMutation,
} from '../../store/slices/apiSlice';
import LoadingButton from '../../components/LoadingButton';

type CollapsibleCheckboxGroupProps = {
  disabled?: boolean;
  selected: string[];
  topLabel: string;
  options: { id: string; entityName?: string }[];
  setSelected: (selected: string[]) => void;
};

function CollapsibleCheckboxGroup({
  disabled,
  selected,
  topLabel,
  options,
  setSelected,
}: CollapsibleCheckboxGroupProps) {
  const [isOpen, setIsOpen] = useState(true);
  const matching = options.filter(({ id }) => selected.includes(id));

  if (options.length === 0) {
    return null;
  }

  const handleToggleAll = () => {
    const optionIds = options.map(({ id }) => id);
    if (matching.length > 0) {
      const matchingIds = matching.map(({ id }) => id);
      if (matching.length === options.length) {
        setSelected(selected.filter((id) => !matchingIds.includes(id)));
      } else {
        const missingIds = optionIds.filter((id) => !matchingIds.includes(id));
        setSelected([...selected, ...missingIds]);
      }
    } else {
      setSelected([...selected, ...optionIds]);
    }
  };

  const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = [...selected];
    const selectedIndex = newSelected.indexOf(e.target.name);
    if (selectedIndex > -1) {
      newSelected.splice(selectedIndex, 1);
      setSelected(newSelected);
    } else {
      setSelected([...newSelected, e.target.name]);
    }
  };

  return (
    <>
      <Box>
        <IconButton
          onClick={() => setIsOpen(!isOpen)}
          size='small'
          aria-label={isOpen ? 'Collapse' : 'Expand'}
          title={isOpen ? 'Collapse' : 'Expand'}
        >
          {isOpen ? <KeyboardArrowDown /> : <ChevronRight />}
        </IconButton>
        <Box ml={1} component='span'>
          <FormControlLabel
            control={
              <Checkbox
                disabled={disabled}
                checked={
                  matching.length > 0 && matching.length === options.length
                }
                indeterminate={
                  matching.length > 0 && matching.length !== options.length
                }
                onChange={handleToggleAll}
              />
            }
            label={topLabel}
          />
        </Box>
        <Collapse in={isOpen}>
          <Box ml={8} display='flex' flexDirection='column'>
            {options.map((option) => (
              <FormControlLabel
                key={option.entityName}
                control={
                  <Checkbox
                    disabled={disabled}
                    checked={selected.includes(option.id)}
                    name={option.id}
                    onChange={handleToggle}
                  />
                }
                label={option.entityName}
              />
            ))}
          </Box>
        </Collapse>
      </Box>
    </>
  );
}

export default function Unsubscribe() {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const email = searchParams.get('email');
  const subscriptionId = searchParams.get('subscriptionId');
  const [selected, setSelected] = useState([subscriptionId].filter(Boolean));
  const [flowComplete, setFlowComplete] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const { data: subscriptions } = useGetSubscriptionsQuery(
    email && subscriptionId ? { email, subscriptionId } : skipToken
  );
  const [deleteSubscriptions, { isLoading }] = useDeleteSubscriptionsMutation();

  // redirect to home if missing required fields, since we cannot render this page
  useEffect(() => {
    if (!email || !subscriptionId) {
      history.push('/');
    }
  }, [email, history, subscriptionId]);

  const unsubscribe = async () => {
    if (email && selected.length > 0) {
      try {
        await deleteSubscriptions({ email, subscriptionIds: selected });
        setFlowComplete(true);
      } catch (e) {
        // error will be surfaced in a toast
      }
    }
  };

  const handleToggleSelectAll = () => {
    if (selectAll) {
      setSelectAll(false);
      setSelected([]);
    } else {
      setSelectAll(true);
      const insightSubscriptionIds =
        subscriptions?.insightSubscriptions.map(({ id }) => id) ?? [];
      const diagnosticMetricAlertSubscriptionIds =
        subscriptions?.diagnosticMetricAlertSubscriptions.map(({ id }) => id) ??
        [];
      setSelected([
        ...insightSubscriptionIds,
        ...diagnosticMetricAlertSubscriptionIds,
      ]);
    }
  };

  return (
    <Box
      height='100vh'
      width='100vw'
      justifyContent='center'
      alignItems='center'
      display='flex'
    >
      <Box
        width={400}
        borderRadius={10}
        px={8}
        py={5}
        border='solid 1px #dadce0'
        display='flex'
        alignItems='center'
        flexDirection='column'
        maxHeight='100%'
        overflow='hidden auto'
      >
        <img src={logo} alt='logo' />
        <Box mt={2}>
          <Typography variant='h2'>Unsubscribe</Typography>
        </Box>
        {!flowComplete && (
          <>
            <Box mt={1} textAlign='center'>
              <Typography>
                Check all of your current emails you would like to unsubscribe
                from
              </Typography>
            </Box>
            <Box mt={1} mb={1}>
              {subscriptions?.insightSubscriptions && (
                <CollapsibleCheckboxGroup
                  disabled={selectAll}
                  selected={selected}
                  setSelected={setSelected}
                  topLabel='All insight update alerts'
                  options={subscriptions.insightSubscriptions}
                />
              )}
              {subscriptions?.diagnosticMetricAlertSubscriptions && (
                <CollapsibleCheckboxGroup
                  disabled={selectAll}
                  selected={selected}
                  setSelected={setSelected}
                  topLabel='All vehicle issue alerts'
                  options={subscriptions.diagnosticMetricAlertSubscriptions}
                />
              )}
              <Box ml={4.75} component='span'>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectAll}
                      onChange={handleToggleSelectAll}
                    />
                  }
                  label={
                    <>
                      <Typography variant='body2'>All subscriptions</Typography>
                      <Typography variant='body2' color='textSecondary'>
                        You will still received required transactional emails
                        from Hopthru
                      </Typography>
                    </>
                  }
                />
              </Box>
            </Box>
            <Grid container spacing={2} style={{ justifyContent: 'center' }}>
              <Grid item>
                <Button variant='outlined' onClick={() => history.push('/')}>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <LoadingButton
                  variant='contained'
                  color='secondary'
                  disabled={selected.length === 0}
                  loading={isLoading}
                  onClick={unsubscribe}
                >
                  Unsubscribe
                </LoadingButton>
              </Grid>
            </Grid>
          </>
        )}
        {flowComplete && (
          <Box mt={1} textAlign='center'>
            <Typography>
              You have been unsubscribed from the selected emails.
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
