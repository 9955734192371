import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  container: {
    height: '100vh',
    width: '100vw',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  logotypeImage: {
    width: 211,
    margin: 'auto',
  },
  formContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
  },
  form: {
    width: 400,
    height: 562,
    borderRadius: 10,
    paddingTop: 32,
    paddingBottom: 32,
    paddingLeft: 20,
    paddingRight: 20,
    border: 'solid 1.5px #dadce0',
    display: 'flex',
    flexDirection: 'column',
  },
  greeting: {
    fontWeight: 500,
    textAlign: 'center',
    marginTop: theme.spacing(4),
  },
  errorMessage: {
    marginTop: 4,
    textAlign: 'center',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
    height: 1,
    backgroundColor: '#dadce0',
  },
  buttonContainer: {
    width: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(4),
    alignItems: 'center',
  },
  buttonStyle: {
    borderRadius: 10,
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 500,
    boxShadow: 'none',
    marginTop: 8,
    marginBottom: 8,
    padding: '4, 2, 4, 2',
  },
  copyright: {
    marginTop: theme.spacing(4),
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      bottom: theme.spacing(2),
    },
  },
}));
