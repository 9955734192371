import { useState } from 'react';

export default function useCookie(cookieName: string) {
  const getCookieValue = (name: string) =>
    document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';

  const [cookieValue, setCookieValue] = useState(
    getCookieValue(cookieName) ?? null
  );

  const setCookie = (value: any) => {
    const expiry = new Date();
    expiry.setDate(expiry.getDate() + 100);
    document.cookie = `${cookieName}=${value}; expires=${expiry.toUTCString()}; path=/`;
    setCookieValue(value);
  };

  const updateCookie = (value: any) => setCookieValue(value);

  return { cookieValue, setCookie, updateCookie };
}
