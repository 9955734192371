import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Input, makeStyles } from '@material-ui/core';
import {
  selectActiveInsight,
  setActiveInsight,
} from '../../store/slices/layoutSlice';
import { selectUser } from '../../store/slices/userSlice';
import AddInsightButton from '../../pages/insights/components/AddInsightButton';

const useStyles = makeStyles((theme) => ({
  input: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    '&.Mui-disabled': {
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
  },
  label: {
    borderRadius: theme.spacing(1),
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: '1rem',
    cursor: 'pointer',
    padding: theme.spacing(0.5, 1),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
    '&:focus-visible': {
      outline: `2px solid ${theme.palette.primary.main}`,
    },
  },
}));

export default function ViewName() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const activeView = useSelector(selectActiveInsight);
  const classes = useStyles();
  const [viewName, setViewName] = useState(activeView?.name);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    // waits until user stops typing to save changes
    const debounceSave = setTimeout(() => {
      if (activeView && viewName && viewName !== activeView.name) {
        dispatch(setActiveInsight({ ...activeView, name: viewName }));
      }
    }, 3000);
    return () => clearTimeout(debounceSave);
  }, [viewName]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (activeView && activeView.name !== viewName) {
      setViewName(activeView.name);
    }
  }, [activeView]); // eslint-disable-line react-hooks/exhaustive-deps

  const exitEditing = () => {
    setIsEditing(false);
    if (!viewName) {
      setViewName(activeView?.name);
    }
  };

  const editable = user.userId === activeView?.creatorId;

  return (
    <>
      {!isEditing && (
        <Box display='flex' alignItems='center'>
          <Box
            tabIndex={0}
            className={classes.label}
            role='button'
            onClick={() => editable && setIsEditing(true)}
            onKeyUp={(e) => {
              if (editable && (e.key === ' ' || e.key === 'Enter')) {
                setIsEditing(true);
              }
            }}
          >
            {viewName}
          </Box>
          <AddInsightButton useV2 showFavorites />
        </Box>
      )}
      {isEditing && (
        <Box px={1}>
          <Input
            value={viewName}
            className={classes.input}
            disableUnderline
            disabled={!editable}
            onChange={(e) => setViewName(e.target.value)}
            onBlur={exitEditing}
            onKeyUp={(e) => {
              if (e.key === 'Escape' || e.key === 'Enter') {
                exitEditing();
              }
            }}
            fullWidth
            autoFocus
          />
        </Box>
      )}
    </>
  );
}
