import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'react-fast-compare';
import queryString from 'query-string';
import {
  selectRouteFilters,
  selectStopFilters,
  selectTripFilters,
  selectFirstDateRange,
  selectSecondDateRange,
  clearRouteFilters,
  clearStopFilters,
  clearTripFilters,
  clearFirstDateRange,
  clearSecondDateRange,
  replaceFirstDateRange,
  replaceSecondDateRange,
  updateRouteFilters,
  updateStopFilters,
  updateTripFilters,
} from '../../store/slices/analyticsFiltersSlice';
import history from '../../utils/history';

export default function useSyncHistoryWithStore() {
  const dispatch = useDispatch();
  const routeFilters = useSelector(selectRouteFilters);
  const stopFilters = useSelector(selectStopFilters);
  const tripFilters = useSelector(selectTripFilters);
  const firstDateRange = useSelector(selectFirstDateRange);
  const secondDateRange = useSelector(selectSecondDateRange);

  history.listen((location, action) => {
    const params = queryString.parse(location.search, {
      arrayFormat: 'comma',
    });

    if ('routeFilters' in params) {
      const paramRouteFilters = JSON.parse(params['routeFilters']);
      if (!isEqual(paramRouteFilters, routeFilters)) {
        dispatch(updateRouteFilters(paramRouteFilters));
      }
    } else if (routeFilters) {
      dispatch(clearRouteFilters());
    }

    if ('stopFilters' in params) {
      const paramStopFilters = JSON.parse(params['stopFilters']);
      if (!isEqual(paramStopFilters, stopFilters)) {
        dispatch(updateStopFilters(paramStopFilters));
      }
    } else if (stopFilters) {
      dispatch(clearStopFilters());
    }

    if ('tripFilters' in params) {
      const paramTripFilters = JSON.parse(params['tripFilters']);
      if (!isEqual(paramTripFilters, tripFilters)) {
        dispatch(updateTripFilters(paramTripFilters));
      }
    } else if (tripFilters) {
      dispatch(clearTripFilters());
    }

    if ('firstDateRange' in params) {
      const paramFirstDateRange = JSON.parse(params['firstDateRange']);
      if (!isEqual(paramFirstDateRange, firstDateRange)) {
        dispatch(replaceFirstDateRange(paramFirstDateRange));
      }
    } else if (firstDateRange) {
      dispatch(clearFirstDateRange());
    }

    if ('secondDateRange' in params) {
      const paramSecondDateRange = JSON.parse(params['secondDateRange']);
      if (!isEqual(paramSecondDateRange, secondDateRange)) {
        dispatch(replaceSecondDateRange(paramSecondDateRange));
      }
    } else if (secondDateRange) {
      dispatch(clearSecondDateRange());
    }
  });
}
