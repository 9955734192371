import React from 'react';
import usePageTitle from '../../utils/hooks/usePageTitle';

// dummy component to host a custom page title updater hook
// without rerendering children on every update
export default function PageTitle() {
  usePageTitle();

  return null;
}
