import React from 'react';
import { Chip, ChipProps } from '@material-ui/core';

export default function BetaTag({ ...props }: Partial<ChipProps>) {
  return (
    <Chip
      color='secondary'
      size='small'
      variant='outlined'
      {...props}
      style={{ pointerEvents: 'none' }}
      label='BETA'
    />
  );
}
