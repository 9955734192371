import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import undoable, { includeAction } from 'redux-undo';
import type { RootState } from '..';
import {
  Insight,
  InsightType,
  InsightFilter,
} from '../../pages/insights/types';
import { devWarning } from '../../utils/utils';
import { MetricsV1 } from '../types';
import { api } from './apiSlice';

type InsightTab = {
  id: string;
  type: InsightType;
  name: string;
};

export type Toast = {
  severity: 'error' | 'warning' | 'info' | 'success';
  message: string;
};

export interface LayoutState {
  collapsiblePanelOpen: boolean;
  toasts: Toast[];
  insights: {
    activeDialog:
      | 'clone'
      | 'edit'
      | 'delete'
      | 'columnEdit'
      | 'agencySwitch'
      | 'share'
      | 'legendEdit'
      | null;
    activePanel: 'create' | 'filter' | 'subscribe' | null;
    activeTabs: InsightTab[];
    activeInsight?: Insight;
    metric?: keyof MetricsV1;
    viewType: 'table' | 'map';
    orderBy?: keyof MetricsV1;
    orderByDirection?: 'asc' | 'desc';
  };
}

export const initialState: LayoutState = {
  collapsiblePanelOpen: true,
  toasts: [],
  insights: {
    activeDialog: null,
    activePanel: null,
    activeTabs: [],
    viewType: 'map',
  },
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    openCollapsiblePanel: (state) => {
      state.collapsiblePanelOpen = true;
    },
    closeCollapsiblePanel: (state) => {
      state.collapsiblePanelOpen = false;
    },
    dequeueToast: (state) => {
      state.toasts = state.toasts.slice(1);
    },
    enqueueToast: (state, action: PayloadAction<Toast>) => {
      state.toasts.push(action.payload);
    },
    setActiveInsightsDialog: (
      state,
      action: PayloadAction<
        | 'clone'
        | 'edit'
        | 'delete'
        | 'columnEdit'
        | 'agencySwitch'
        | 'share'
        | 'legendEdit'
        | null
      >
    ) => {
      state.insights.activeDialog = action.payload;
    },
    setActiveInsightsPanel: (
      state,
      action: PayloadAction<'create' | 'filter' | 'subscribe' | null>
    ) => {
      state.insights.activePanel = action.payload;
    },
    setActiveInsight: (state, action: PayloadAction<Insight>) => {
      state.insights.activeInsight = action.payload;
    },
    addInsightFilter: (state, action: PayloadAction<InsightFilter>) => {
      const activeInsight = state.insights.activeInsight;
      if (!activeInsight) {
        devWarning('Attempting to update active insight before it exists');
        return;
      }

      activeInsight.filters = activeInsight.filters || [];
      activeInsight.filters.push(action.payload);
    },
    updateInsightFilterAtIndex: (
      state,
      action: PayloadAction<{ index: number; filter: InsightFilter }>
    ) => {
      const activeInsight = state.insights.activeInsight;
      if (!activeInsight) {
        devWarning('Attempting to update active insight before it exists');
        return;
      }

      if (action.payload.index > -1 && activeInsight.filters) {
        activeInsight.filters.splice(
          action.payload.index,
          1,
          action.payload.filter
        );
      }
    },
    removeInsightFilterAtIndex: (state, action: PayloadAction<number>) => {
      const activeInsight = state.insights.activeInsight;
      if (!activeInsight) {
        devWarning('Attempting to update active insight before it exists');
        return;
      }

      if (action.payload > -1 && activeInsight.filters) {
        activeInsight.filters.splice(action.payload, 1);
      }
    },
    removeInsightFilters: (state) => {
      const activeInsight = state.insights.activeInsight;
      if (!activeInsight) {
        devWarning('Attempting to update active insight before it exists');
        return;
      }

      activeInsight.filters = [];
    },
    addInsightTab: (state, action: PayloadAction<InsightTab>) => {
      if (!state.insights.activeTabs) {
        state.insights.activeTabs = [];
      }

      if (
        !state.insights.activeTabs.find((tab) => tab.id === action.payload.id)
      ) {
        state.insights.activeTabs.push(action.payload);
      }
    },
    removeInsightTab: (state, action: PayloadAction<InsightTab>) => {
      const index = state.insights.activeTabs.findIndex(
        (tab) => tab.id === action.payload.id
      );
      if (index > -1) {
        state.insights.activeTabs.splice(index, 1);
      }
    },
    setInsightTabs: (state, action: PayloadAction<InsightTab[]>) => {
      state.insights.activeTabs = action.payload;
    },
    setInsightViewType: (state, action: PayloadAction<'table' | 'map'>) => {
      state.insights.viewType = action.payload;
    },
    setInsightMetric: (state, action: PayloadAction<keyof MetricsV1>) => {
      state.insights.metric = action.payload;
    },
    setInsightOrderParameters: (
      state,
      action: PayloadAction<{
        orderBy?: keyof MetricsV1;
        orderByDirection?: 'asc' | 'desc';
      }>
    ) => {
      state.insights.orderBy = action.payload.orderBy;
      state.insights.orderByDirection = action.payload.orderByDirection;
    },
    clearInsightMetric: (state) => {
      state.insights.metric = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        api.endpoints.updateInsight.matchFulfilled,
        (state, { payload }) => {
          const { id, name, insightType } = payload;
          const index = state.insights.activeTabs.findIndex(
            (tab) => tab.id === id
          );
          if (index > -1) {
            state.insights.activeTabs[index] = { id, name, type: insightType };
          }
        }
      )
      .addMatcher(
        api.endpoints.deleteInsight.matchFulfilled,
        (state, { meta }) => {
          const index = state.insights.activeTabs.findIndex(
            (tab) => tab.id === meta.arg.originalArgs.insightId
          );
          if (index > -1) {
            state.insights.activeTabs.splice(index, 1);
          }
        }
      );
  },
});

// ACTIONS
export const {
  addInsightTab,
  clearInsightMetric,
  dequeueToast,
  enqueueToast,
  openCollapsiblePanel,
  closeCollapsiblePanel,
  setActiveInsightsDialog,
  setActiveInsightsPanel,
  setActiveInsight,
  setInsightMetric,
  setInsightViewType,
  setInsightTabs,
  setInsightOrderParameters,
  addInsightFilter,
  removeInsightFilterAtIndex,
  removeInsightFilters,
  removeInsightTab,
  updateInsightFilterAtIndex,
} = layoutSlice.actions;

// SELECTORS
const getPresent = (state: RootState) => {
  if (state.layout.present) {
    return state.layout.present;
  }

  return state.layout as typeof state.layout.present;
};

export const selectCollapsiblePanelOpen = (state: RootState) =>
  getPresent(state).collapsiblePanelOpen;
export const selectActiveInsightDialog = (state: RootState) =>
  getPresent(state).insights.activeDialog;
export const selectActiveInsightPanel = (state: RootState) =>
  getPresent(state).insights.activePanel;
export const selectActiveInsight = (state: RootState) =>
  getPresent(state).insights.activeInsight;
export const selectActiveInsightTabs = (state: RootState) =>
  getPresent(state).insights.activeTabs;
export const selectToasts = (state: RootState) => getPresent(state).toasts;
export const selectInsightViewType = (state: RootState) =>
  getPresent(state).insights.viewType;
export const selectInsightMetric = (state: RootState) =>
  getPresent(state).insights.metric;
export const selectInsightOrderParameters = (state: RootState) => ({
  orderBy: getPresent(state).insights.orderBy,
  orderByDirection: getPresent(state).insights.orderByDirection,
});
export const selectHistoryState = (state: RootState) => ({
  canUndo: (state.layout.past?.length ?? 0) > 0,
  canRedo: (state.layout.future?.length ?? 0) > 0,
});

// REDUCER
export default undoable(layoutSlice.reducer, {
  filter: includeAction([
    setActiveInsight.type,
    addInsightFilter.type,
    updateInsightFilterAtIndex.type,
    removeInsightFilters.type,
    removeInsightFilterAtIndex.type,
  ]),
  ignoreInitialState: true,
});
