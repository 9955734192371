import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAgency } from '../store/slices/userSlice';

interface LayoutStateContextValue {
  previousPath?: string;
  setPreviousPath: (path: string) => void;
  previousStop?: string;
  setPreviousStop: (stop: string) => void;
  previousRoute?: string;
  setPreviousRoute: (route: string) => void;
  previousTrip?: string;
  setPreviousTrip: (trip: string) => void;
  dataExpanded: boolean;
  setDataExpanded: (expanded: boolean) => void;
  nonGTFSRoute: boolean;
  setNonGTFSRoute: (nonGTFS: boolean) => void;
}

const LayoutStateContext = React.createContext<
  LayoutStateContextValue | undefined
>(undefined);

function LayoutProvider({ children }: { children?: React.ReactNode }) {
  const { showExpand } = useSelector(selectAgency);
  const [previousPath, setPreviousPath] = useState<string>();
  const [previousStop, setPreviousStop] = useState<string>();
  const [previousRoute, setPreviousRoute] = useState<string>();
  const [previousTrip, setPreviousTrip] = useState<string>();
  const [dataExpanded, setDataExpanded] = useState(showExpand);
  const [nonGTFSRoute, setNonGTFSRoute] = useState(false);
  return (
    <LayoutStateContext.Provider
      value={{
        previousPath,
        setPreviousPath,
        previousStop,
        setPreviousStop,
        previousRoute,
        setPreviousRoute,
        previousTrip,
        setPreviousTrip,
        dataExpanded,
        setDataExpanded,
        nonGTFSRoute,
        setNonGTFSRoute,
      }}
    >
      {children}
    </LayoutStateContext.Provider>
  );
}

function useLayoutState() {
  const context = React.useContext(LayoutStateContext);
  if (context === undefined) {
    throw new Error('useLayoutState must be used within a LayoutProvider');
  }
  return context;
}

export { LayoutProvider, useLayoutState };
