import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import HttpsRedirect from 'react-https-redirect';
import { Router } from 'react-router-dom';
import { store } from './store';
import { Provider } from 'react-redux';
import history from './utils/history';

import Themes from './themes';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { LayoutProvider } from './context/LayoutContext';
import FeatureProvider from './context/FeatureProvider';
import ToastManager from './components/ToastManager';
import * as Sentry from '@sentry/react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Provider store={store}>
    <LayoutProvider>
      <FeatureProvider>
        <ThemeProvider theme={Themes.default}>
          <CssBaseline />
          <HttpsRedirect>
            <ToastManager />
            <Router history={history}>
              <App />
            </Router>
          </HttpsRedirect>
        </ThemeProvider>
      </FeatureProvider>
    </LayoutProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
