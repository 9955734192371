import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/userSlice';
import analyticsFiltersReducer from './slices/analyticsFiltersSlice';
import layoutReducer from './slices/layoutSlice';
import settingsFiltersReducer from './slices/settingsSlice';
import { api } from './slices/apiSlice';
import apiMiddleware from './middlewares/apiMiddleware';
import layoutMiddleware from './middlewares/layoutMiddleware';
import userMiddleware from './middlewares/userMiddleware';
import * as Sentry from '@sentry/react';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state) => ({
    ...state,
    api: null,
  }),
});

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    user: userReducer,
    analyticsFilters: analyticsFiltersReducer,
    layout: layoutReducer,
    settingsFilters: settingsFiltersReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(layoutMiddleware)
      .concat(userMiddleware)
      .concat(apiMiddleware)
      .concat(api.middleware),
  enhancers: [sentryReduxEnhancer],
});

export type RootState = ReturnType<typeof store.getState>;
