import React from 'react';
import clsx from 'clsx';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  headerMenuButtonActive: {
    backgroundColor: '#e4e4e4',
    border: '1.5px solid #202124',
  },
  headerMenuButton: {
    border: '1.5px solid #DADCE0',
    borderRadius: 50,
    fontSize: '2rem',
    height: theme.spacing(5.25),
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1),
    width: theme.spacing(5.25),
  },
}));

export default function HeaderMenuButton({ isActive, className, ...props }) {
  const classes = useStyles();

  return (
    <IconButton
      {...props}
      className={clsx(classes.headerMenuButton, {
        [classes.headerMenuButtonActive]: isActive,
        [className]: !!className,
      })}
    />
  );
}
