import { useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/userSlice';
import { selectFirstDateRange } from '../../store/slices/analyticsFiltersSlice';
import { selectActiveInsight } from '../../store/slices/layoutSlice';
import { api } from '../../store/slices/apiSlice';

export default function usePageTitle() {
  const location = useLocation();
  const activeInsight = useSelector(selectActiveInsight);
  const { isAuthenticated } = useSelector(selectUser);
  const firstDateRange = useSelector(selectFirstDateRange);

  const { unreadCount } = api.endpoints.getUnreadNotifications.useQueryState(
    undefined,
    {
      selectFromResult: ({ data }) => ({
        unreadCount: data?.unread_count ?? 0,
      }),
    }
  );

  const { stopName } = api.endpoints.getStop.useQueryState(
    {
      stopId: firstDateRange?.stopIds?.[0],
    },
    {
      selectFromResult: ({ currentData }) => ({
        stopName: currentData?.stopName,
      }),
    }
  );

  const { routeName } = api.endpoints.getRouteDescription.useQueryState(
    {
      routeId: firstDateRange?.routeIds?.[0],
    },
    {
      selectFromResult: ({ currentData }) => ({
        routeName: currentData?.routeShortName,
      }),
    }
  );

  const getTitleForPath = useCallback(
    (path: string) => {
      switch (path) {
        case '/login':
          return 'Login';
        case '/signup':
          return 'Sign up';
        case '/reset':
          return 'Reset password';
        case '/system':
          return 'System';
        case '/routes':
          return 'Routes';
        case path.match(/\/routes\/(summary|trips|tags)/)?.input:
          return routeName ? `Route ${routeName}` : 'Routes';
        case '/stops':
          return 'Stops';
        case path.match(/\/stops\/summary/)?.input:
          return stopName ?? 'Stops';
        case path.match(/\/trips\/details/)?.input:
          return routeName
            ? `Trip details | Route ${routeName}`
            : 'Trip details';
        case '/insights':
        case '/insights/all':
          return 'Insights';
        case path.match(/\/(insights|views)\/[0-9a-fA-F]+/)?.input:
          return activeInsight ? activeInsight.name : 'Insights';
        case path.match(/\/settings.*/)?.input:
          return 'Settings';
        default:
          return '';
      }
    },
    [activeInsight, routeName, stopName]
  );

  useEffect(() => {
    let title = 'Hopthru';
    const pageTitle = getTitleForPath(location.pathname);
    if (pageTitle) {
      title = `${pageTitle} | ${title}`;
    }

    if (isAuthenticated && unreadCount > 0) {
      title = `(${unreadCount}) ${title}`;
    }

    document.title = title;
  }, [isAuthenticated, getTitleForPath, location, unreadCount]);
}
