import moment from 'moment';
import {
  BaseActivity,
  ActivityWithExpanded,
  ActivityWithLoad,
  Comparison,
  DayReport,
  DateReport,
  PeriodReport,
  RouteDetail,
  RouteSummary,
  StopDetail,
  StopSegmentLoad,
  StopV2,
  TripDetail,
  TripV2,
} from '../store/types';
import { InsightActivity } from '../pages/insights/types';

const emptyResponse = {
  sum_ons: 0,
  sum_offs: 0,
  avg_ons: 0,
  avg_offs: 0,
  sum_activity: 0,
  avg_activity: 0,
  unexpanded_trip_count: 0,
  total_trip_count: 0,
};

const emptyResponseV2: BaseActivity & {
  unexpandedTripCount: number;
  totalTripCount: number;
} = {
  sumOns: 0,
  sumOffs: 0,
  avgOns: 0,
  avgOffs: 0,
  sumActivity: 0,
  avgActivity: 0,
  unexpandedTripCount: 0,
  totalTripCount: 0,
};

const emptyLoadResponse = {
  avg_load: 0,
  maxload: 0,
  sum_load: 0,
  unexpanded_trip_count: 0,
  total_trip_count: 0,
};

const emptyLoadResponseV2 = {
  avgLoad: 0,
  maxLoad: 0,
  sumLoad: 0,
  avgMaxLoad: 0,
  avgPassengerDistanceTravelled: 0,
  sumPassengerDistanceTravelled: 0,
  unexpandedTripCount: 0,
  totalTripCount: 0,
};

const sleep = (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

const clearSessionStorage = () => {
  const agencyId = sessionStorage.getItem('agency_id');
  const userRole = sessionStorage.getItem('user_role');
  sessionStorage.clear();
  agencyId && sessionStorage.setItem('agency_id', agencyId);
  userRole && sessionStorage.setItem('user_role', userRole);
};

function checkCache(cacheKey: string) {
  let cachedHits = sessionStorage.getItem(cacheKey);
  const now = new Date().getTime();
  const setupTime = localStorage.getItem('setup_time');
  if (setupTime === null) {
    localStorage.setItem('setup_time', `${now}`);
    cachedHits = null;
  } else {
    if (now - Number(setupTime) > 60 * 60 * 1000) {
      cachedHits = null;
      clearSessionStorage();
      localStorage.setItem('setup_time', `${now}`);
    }
  }
  if (cachedHits) {
    const now = new Date().getTime();
    if (setupTime === null) {
      localStorage.setItem('setup_time', `${now}`);
    }
    cachedHits = JSON.parse(cachedHits);
  }
  return cachedHits;
}

function cacheResult(key: string, value: object) {
  sessionStorage.setItem(key, JSON.stringify(value));
}

function formatNumberString(num?: number, fixed?: number) {
  const fixedPlaces = fixed === undefined ? 2 : fixed;
  if (num !== undefined) {
    let [integral, fractional] = num.toFixed(fixedPlaces).split('.');
    integral = integral.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (!fractional || fractional === '00') {
      return integral;
    }
    return `${integral}.${fractional}`;
  }
  return '0';
}

function formatAbbreviatedNumberString(string: string) {
  const COUNT_FORMATS = [
    {
      // 0 - 999
      letter: '',
      limit: 1e3,
    },
    {
      // 1,000 - 999,999
      letter: 'K',
      limit: 1e6,
    },
    {
      // 1,000,000 - 999,999,999
      letter: 'M',
      limit: 1e9,
    },
    {
      // 1,000,000,000 - 999,999,999,999
      letter: 'B',
      limit: 1e12,
    },
    {
      // 1,000,000,000,000 - 999,999,999,999,999
      letter: 'T',
      limit: 1e15,
    },
  ];
  const number = parseFloat(string);
  if (!isNaN(number)) {
    const format =
      COUNT_FORMATS.find((format) => number < format.limit) || COUNT_FORMATS[0];
    let formattedValue = (1000 * number) / format.limit;
    formattedValue = Math.round(formattedValue * 10) / 10;
    return `${formattedValue}${format.letter}`;
  }
  return '0';
}

function sortAlphaNum(a: string | number, b: string | number) {
  a = a || '';
  b = b || '';
  a = typeof a === 'number' ? a.toString() : a;
  b = typeof b === 'number' ? b.toString() : b;
  return (
    Number(a) - Number(b) ||
    a.localeCompare(b, undefined, {
      numeric: true,
      sensitivity: 'base',
    })
  );
}

// Removes all empty (null/undefined) properties from an object.
// If falsy is set to true, removes all falsy properties instead.
// Returns a new object without the empty properties.
function filterEmptyProperties(obj: object, falsy = false) {
  const filtered = Object.fromEntries(
    Object.entries(obj).filter(([_, value]) =>
      falsy ? !!value : value !== null && value !== undefined
    )
  );
  return filtered;
}

// Fast deep clone that only works with JSON-safe objects.
// Function and object properties will be lost.
function lossyDeepClone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

function roundToTwoDecimals(num: number) {
  var m = Number((Math.abs(num) * 100).toPrecision(15));
  return (Math.round(m) / 100) * Math.sign(num);
}

function getTotalDays(dateRange: DateRange) {
  return (
    moment(dateRange.endDate).diff(moment(dateRange.startDate), 'days') + 1
  );
}

function changeKeytoSecond(secondElement: ObjectMap) {
  const updatedElement: ObjectMap = {};
  for (const kpiKey of Object.keys(secondElement)) {
    if (kpiKey.includes('first')) {
      const newkey = kpiKey.replace('first', 'second');
      updatedElement[newkey] = secondElement[kpiKey];
    } else {
      updatedElement[kpiKey] = secondElement[kpiKey];
    }
  }
  return updatedElement;
}

function determineGroupByFromTotalDays(totalDays: number) {
  if (totalDays <= 13) {
    return 'day';
  } else if (totalDays <= 98) {
    return 'week';
  } else if (totalDays <= 730) {
    return 'month';
  }
  return 'year';
}

function calculatePercentChange<T extends ObjectMap>(first: T, second: T): T {
  const avg: ObjectMap = {};
  const keys = [...Object.keys(first ?? {}), ...Object.keys(second ?? {})];
  for (const key of keys) {
    let firstStat = getCompositeActivityValue(
      first as unknown as BaseActivity,
      key
    );
    let secondStat = getCompositeActivityValue(
      second as unknown as BaseActivity,
      key
    );
    if (firstStat === secondStat) {
      avg[key] = 0;
    } else if (firstStat && secondStat) {
      avg[key] = roundToTwoDecimals(
        ((firstStat - secondStat) / secondStat + Number.EPSILON) * 100
      );
    } else if (!firstStat) {
      avg[key] = -100;
    } else {
      avg[key] = 100;
    }
  }
  return avg as T;
}

function combineInsightActivityResult(
  first: InsightActivity[],
  second: InsightActivity[]
) {
  const firstResult = lossyDeepClone(first);
  const secondResult = lossyDeepClone(second);
  const result: {
    first: InsightActivity;
    second: InsightActivity;
    percent_change: InsightActivity;
  }[] = [];
  for (const idx in firstResult) {
    const firstStat = firstResult[idx];
    const secondStat = secondResult[idx];
    const percentChange = calculatePercentChange(firstStat, secondStat);
    result[idx] = {
      first: firstStat,
      second: secondStat,
      percent_change: percentChange,
    };
  }
  return result;
}

function combineSystemResult(
  firstResult: ActivityWithExpanded,
  secondResult: ActivityWithExpanded
) {
  const first = lossyDeepClone(firstResult);
  const second = lossyDeepClone(secondResult);
  return {
    first,
    second,
    percentChange: calculatePercentChange(first, second),
  };
}

function combineDaysResult(first: DayReport[], second: DayReport[]) {
  const firstResult = lossyDeepClone(first);
  const secondResult = lossyDeepClone(second);
  const result = [];
  const keys = ['Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa'];
  for (const key of keys) {
    let firstDays = {};
    let secondDays = {};
    for (const firstElement of firstResult) {
      if (firstElement['name'] === key) {
        firstDays = firstElement;
      }
    }
    for (const secondElement of secondResult) {
      if (secondElement['name'] === key) {
        secondDays = secondElement;
      }
    }
    if (Object.keys(firstDays).length > 0) {
      result.push({ first: firstDays, second: secondDays });
    }
  }
  return result;
}

function combineDatesResult(first: DateReport[], second: DateReport[]) {
  const firstResult = lossyDeepClone(first);
  const secondResult = lossyDeepClone(second);
  const result: { first: DateReport; second: DateReport }[] = [];
  for (const idx in firstResult) {
    const firstStat = firstResult[idx];
    const secondStat = secondResult[idx];
    result[idx] = { first: firstStat, second: secondStat };
  }
  return result;
}

function combinePeriodsResult(
  first: (PeriodReport | undefined)[],
  second: (PeriodReport | undefined)[]
) {
  const firstResult = lossyDeepClone(first);
  const secondResult = lossyDeepClone(second);
  let result = [];
  for (const idx in firstResult) {
    const combined = { first: firstResult[idx], second: secondResult[idx] };
    if (Object.keys(combined).length > 0) result.push(combined);
  }
  return result;
}

function combineRoutesSummaryResult(
  first: { data: ObjectMap[] },
  second: { data: ObjectMap[] }
) {
  const firstResult = lossyDeepClone(first);
  const secondResult = lossyDeepClone(second);
  let result: ObjectMap = {};
  for (const firstEntry of firstResult.data) {
    firstEntry.second = emptyResponse;
    firstEntry.percent_change = calculatePercentChange(
      firstEntry.first,
      firstEntry.second
    );
    result[firstEntry['id']] = firstEntry;
  }
  for (const secondEntry of secondResult.data) {
    if (secondEntry['id'] in result) {
      result[secondEntry['id']].second = secondEntry.first;
      result[secondEntry['id']].percent_change = calculatePercentChange(
        result[secondEntry['id']].first,
        result[secondEntry['id']].second
      );
    } else {
      secondEntry.second = secondEntry.first;
      secondEntry.first = emptyResponse;
      result[secondEntry['id']] = secondEntry;
      result[secondEntry['id']].percent_change = calculatePercentChange(
        result[secondEntry['id']].first,
        result[secondEntry['id']].second
      );
    }
  }
  return { data: Object.values(result) };
}

function combineRoutesSummaryResultV2(
  first: RouteSummary[],
  second: RouteSummary[]
) {
  const firstResult = lossyDeepClone(first);
  const secondResult = lossyDeepClone(second);
  let result: ObjectMap = {};
  for (const firstEntry of firstResult) {
    const { activity, ...rest } = firstEntry;
    const combinedEntry = {
      ...rest,
      first: activity,
      second: emptyResponseV2,
      percent_change: calculatePercentChange(activity, emptyResponseV2),
    };
    result[firstEntry['id']] = combinedEntry;
  }
  for (const secondEntry of secondResult) {
    if (secondEntry['id'] in result) {
      result[secondEntry['id']].second = secondEntry.activity;
      result[secondEntry['id']].percent_change = calculatePercentChange(
        result[secondEntry['id']].first,
        result[secondEntry['id']].second
      );
    } else {
      const { activity, ...rest } = secondEntry;
      const combinedEntry = {
        ...rest,
        first: emptyResponseV2,
        second: activity,
        percent_change: calculatePercentChange(emptyResponseV2, activity),
      };
      result[secondEntry['id']] = combinedEntry;
    }
  }
  return Object.values(result);
}

function combineRoutesDetailResult(first: RouteDetail, second: RouteDetail) {
  const firstResult = lossyDeepClone(first);
  const secondResult = lossyDeepClone(second);
  let stops: ObjectMap = {};
  for (const firstEntry of firstResult.stops) {
    const { activity, ...rest } = firstEntry;
    const combinedEntry = {
      ...rest,
      first: activity,
      second: emptyResponseV2,
      percent_change: calculatePercentChange(activity, emptyResponseV2),
    };
    stops[firstEntry['id']] = combinedEntry;
  }
  for (const secondEntry of secondResult.stops) {
    if (secondEntry['id'] in stops) {
      const firstEntry = stops[secondEntry['id']];
      firstEntry.second = secondEntry.activity;
      firstEntry.percent_change = calculatePercentChange(
        firstEntry.first,
        firstEntry.second
      );
      stops[firstEntry['id']] = firstEntry;
    } else {
      const { activity, ...rest } = secondEntry;
      const combinedEntry = {
        ...rest,
        first: emptyResponseV2,
        second: activity,
        percent_change: calculatePercentChange(emptyResponseV2, activity),
      };
      stops[secondEntry['id']] = combinedEntry;
    }
  }
  return {
    ...firstResult,
    trips: firstResult.trips,
    stops: Object.values(stops),
  };
}

function combineRoutesLoadResult(first: ObjectMap, second: ObjectMap) {
  const firstResult = lossyDeepClone(first);
  const secondResult = lossyDeepClone(second);
  let stops: ObjectMap = {};
  for (const [segmentId, firstEntry] of Object.entries(
    firstResult.data.stops as ObjectMap
  )) {
    firstEntry.second = emptyLoadResponse;
    firstEntry.percent_change = calculatePercentChange(
      firstEntry.first,
      firstEntry.second
    );
    stops[segmentId] = firstEntry;
  }
  for (const [segmentId, secondEntry] of Object.entries(
    secondResult.data.stops as ObjectMap
  )) {
    if (segmentId in stops) {
      const firstEntry = stops[segmentId];
      firstEntry.second = secondEntry.first;
      firstEntry.percent_change = calculatePercentChange(
        firstEntry.first,
        firstEntry.second
      );
      stops[firstEntry['id']] = firstEntry;
    } else {
      secondEntry.second = secondEntry.first;
      secondEntry.first = emptyLoadResponse;
      secondEntry.percent_change = calculatePercentChange(
        secondEntry.first,
        secondEntry.second
      );
      stops[segmentId] = secondEntry;
    }
  }
  firstResult.data.stops = stops;
  return firstResult;
}

function combineRoutesLoadResultV2(
  first: StopSegmentLoad[],
  second: StopSegmentLoad[]
) {
  const firstResult = lossyDeepClone(first);
  const secondResult = lossyDeepClone(second);
  let stops: ObjectMap = {};
  for (const firstEntry of firstResult) {
    const { activity, ...rest } = firstEntry;
    const segmentId = `${firstEntry.id}_${firstEntry.followingId}`;
    stops[segmentId] = {
      ...rest,
      first: activity,
      second: emptyLoadResponseV2,
    };
  }
  for (const secondEntry of secondResult) {
    const segmentId = `${secondEntry.id}_${secondEntry.followingId}`;
    if (segmentId in stops) {
      const firstEntry = stops[segmentId];
      firstEntry.second = secondEntry.activity;
      firstEntry.percent_change = calculatePercentChange(
        firstEntry.first,
        firstEntry.second
      );
      stops[segmentId] = firstEntry;
    } else {
      const { activity, ...rest } = secondEntry;
      stops[segmentId] = {
        ...rest,
        first: emptyLoadResponseV2,
        second: activity,
      };
    }
  }
  return Object.values(stops);
}

function combineTripSummaryResult(
  first: { trips: TripV2[] },
  second: { trips: TripV2[] }
): (TripV2 & Comparison<ActivityWithLoad>)[] {
  const firstResult = lossyDeepClone(first);
  const secondResult = lossyDeepClone(second);
  let result: ObjectMap = {};
  for (const firstEntry of firstResult.trips) {
    const { activity = emptyResponseV2, ...rest } = firstEntry;
    result[firstEntry['id']] = {
      ...rest,
      first: activity,
      second: emptyResponseV2,
      percent_change: calculatePercentChange(activity, emptyResponseV2),
    };
  }
  for (const secondEntry of secondResult.trips) {
    if (secondEntry['id'] in result) {
      result[secondEntry['id']].second = secondEntry.activity;
      result[secondEntry['id']].percent_change = calculatePercentChange(
        result[secondEntry['id']].first,
        result[secondEntry['id']].second
      );
    } else {
      const { activity = emptyResponseV2, ...rest } = secondEntry;
      result[secondEntry['id']] = {
        ...rest,
        first: emptyResponseV2,
        second: activity,
        percent_change: calculatePercentChange(emptyResponseV2, activity),
      };
    }
  }
  return Object.values(result);
}

function combineTripDetailResult(
  first: TripDetail,
  second: TripDetail
): {
  trip: TripV2 & Comparison<ActivityWithLoad>;
  stops: (StopV2 & Comparison<ActivityWithExpanded>)[];
} {
  const firstResult = lossyDeepClone(first);
  const secondResult = lossyDeepClone(second);
  const trip = {
    ...firstResult.trip,
    first: firstResult.trip.activity,
    second: secondResult.trip.activity,
    percent_change: calculatePercentChange(
      firstResult.trip.activity ?? {
        ...emptyResponseV2,
        ...emptyLoadResponseV2,
      },
      secondResult.trip.activity ?? {
        ...emptyResponseV2,
        ...emptyLoadResponseV2,
      }
    ),
  };
  let stops: ObjectMap = {};
  for (const firstEntry of firstResult.stops) {
    stops[firstEntry['id']] = {
      ...firstEntry,
      first: firstEntry.activity,
      second: emptyResponseV2,
      percent_change: calculatePercentChange(
        firstEntry.activity,
        emptyResponseV2
      ),
    };
  }
  for (const secondEntry of secondResult.stops) {
    if (secondEntry['id'] in stops) {
      const firstEntry = stops[secondEntry['id']];
      firstEntry.second = secondEntry.activity;
      firstEntry.percent_change = calculatePercentChange(
        firstEntry.first,
        firstEntry.second
      );
      stops[firstEntry['id']] = firstEntry;
    } else {
      stops[secondEntry['id']] = {
        ...secondEntry,
        first: emptyResponseV2,
        second: secondEntry.activity,
        percent_change: calculatePercentChange(
          emptyResponseV2,
          secondEntry.activity
        ),
      };
    }
  }
  return { trip, stops: Object.values(stops) };
}

function combineStopsSummaryResult(
  first: { data: ObjectMap[] },
  second: { data: ObjectMap[] }
) {
  const firstResult = lossyDeepClone(first);
  const secondResult = lossyDeepClone(second);
  let result: ObjectMap = {};
  for (const [, firstEntry] of firstResult.data.entries()) {
    firstEntry.second = emptyResponse;
    firstEntry.percent_change = calculatePercentChange(
      firstEntry.first,
      firstEntry.second
    );
    result[firstEntry['id']] = firstEntry;
  }
  for (const secondEntry of secondResult.data) {
    if (secondEntry['id'] in result) {
      result[secondEntry['id']].second = secondEntry.first;
      result[secondEntry['id']].percent_change = calculatePercentChange(
        result[secondEntry['id']].first,
        result[secondEntry['id']].second
      );
    } else {
      secondEntry.second = secondEntry.first;
      secondEntry.first = emptyResponse;
      result[secondEntry['id']] = secondEntry;
      result[secondEntry['id']].percent_change = calculatePercentChange(
        result[secondEntry['id']].first,
        result[secondEntry['id']].second
      );
    }
  }
  return { data: Object.values(result) };
}

function combineStopsSummaryResultV2(first: StopV2[], second: StopV2[]) {
  const firstResult = lossyDeepClone(first);
  const secondResult = lossyDeepClone(second);
  let result: ObjectMap = {};
  for (const firstEntry of firstResult) {
    const { activity, ...rest } = firstEntry;
    result[firstEntry['id']] = {
      ...rest,
      first: activity,
      second: emptyResponseV2,
      percent_change: calculatePercentChange(activity, emptyResponseV2),
    };
  }
  for (const secondEntry of secondResult) {
    if (secondEntry['id'] in result) {
      result[secondEntry['id']].second = secondEntry.activity;
      result[secondEntry['id']].percent_change = calculatePercentChange(
        result[secondEntry['id']].first,
        result[secondEntry['id']].second
      );
    } else {
      const { activity, ...rest } = secondEntry;
      result[secondEntry['id']] = {
        ...rest,
        first: emptyResponseV2,
        second: activity,
        percent_change: calculatePercentChange(emptyResponseV2, activity),
      };
    }
  }
  return Object.values(result);
}

function combineStopsDetailResult(first: StopDetail[], second: StopDetail[]) {
  const firstResult = lossyDeepClone(first);
  const secondResult = lossyDeepClone(second);
  const emptyResult = { ...emptyResponseV2, ...emptyLoadResponseV2 };
  let result: ObjectMap = {};
  for (const firstEntry of firstResult) {
    const { activity, route } = firstEntry;
    result[firstEntry.route.id] = {
      route,
      first: activity,
      second: emptyResult,
    };
  }
  for (let secondEntry of secondResult) {
    if (secondEntry.route.id in result) {
      result[secondEntry.route.id].second = secondEntry.activity;
    } else {
      const { activity, route } = secondEntry;
      result[secondEntry.route.id] = {
        route,
        first: emptyResult,
        second: activity,
      };
    }
  }
  return Object.values(result);
}

const debounce = (callback: Function, wait: number) => {
  let timeout: ReturnType<typeof setTimeout>;
  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
};

const stableSort = <T>(array: T[], comparator: (a: T, b: T) => number) => {
  const stabilizedThis: [T, number][] = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const getMapboxAccessToken = () => process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const devWarning = (...args: Parameters<typeof console.warn>) => {
  if (process.env.NODE_ENV === 'development') {
    console.warn('[DEV WARNING]:', ...args);
  }
};

const getExpandedPercent = <
  T extends {
    first?: { total_trip_count?: number; unexpanded_trip_count?: number };
    second?: { total_trip_count?: number; unexpanded_trip_count?: number };
  }
>(
  data: T
) => {
  let total_trip_count = data.first?.total_trip_count || 0;
  let unexpanded_trip_count = data.first?.unexpanded_trip_count || 0;
  if (data.second) {
    total_trip_count += data.second.total_trip_count || 0;
    unexpanded_trip_count += data.second.unexpanded_trip_count || 0;
  }
  if (total_trip_count && total_trip_count > 0) {
    return Math.round((unexpanded_trip_count / total_trip_count) * 100);
  }
  return 0;
};

const getExpandedPercentV2 = <
  T extends {
    first?: { totalTripCount?: number; unexpandedTripCount?: number };
    second?: { totalTripCount?: number; unexpandedTripCount?: number };
  }
>(
  data: T
) => {
  let totalTripCount = data.first?.totalTripCount || 0;
  let unexpandedTripCount = data.first?.unexpandedTripCount || 0;
  if (data.second) {
    totalTripCount += data.second.totalTripCount || 0;
    unexpandedTripCount += data.second.unexpandedTripCount || 0;
  }
  if (totalTripCount && totalTripCount > 0) {
    return Math.round((unexpandedTripCount / totalTripCount) * 100);
  }
  return 0;
};

const metricV2ToV1 = (metricName: string): MetricType => {
  if (metricName === 'maxLoad') {
    return 'maxload';
  }

  return metricName.replace(
    /[A-Z]/g,
    (m) => '_' + m.toLowerCase()
  ) as MetricType;
};

const getCompositeActivityValue = <T extends BaseActivity>(
  activity: T | undefined,
  keyId: string
) => {
  let value = activity?.[keyId as keyof BaseActivity];
  if (typeof value !== 'number') {
    value = value?.total;
  }
  return value;
};

const generateDualDateRangeCompositeMetrics = <T extends ObjectMap>(
  activity: T
) => {
  const compositeActivity: ObjectMap = lossyDeepClone(activity);
  if (activity.avgActivity) {
    compositeActivity.avgActivity = {
      first: {
        ons: activity.avgOns.first,
        offs: activity.avgOffs.first,
        total: activity.avgActivity.first,
      },
      second: {
        ons: activity.avgOns.second,
        offs: activity.avgOffs.second,
        total: activity.avgActivity.second,
      },
      percentChange: activity.avgActivity.percentChange,
    };
  }
  if (activity.sumActivity) {
    compositeActivity.sumActivity = {
      first: {
        ons: activity.sumOns.first,
        offs: activity.sumOffs.first,
        total: activity.sumActivity.first,
      },
      second: {
        ons: activity.sumOns.second,
        offs: activity.sumOffs.second,
        total: activity.sumActivity.second,
      },
      percentChange: activity.sumActivity.percentChange,
    };
  }
  return compositeActivity;
};

const generateCompositeMetrics = <T extends BaseActivity>(activity: T) => {
  const compositeActivity = { ...activity };
  if (
    activity.avgActivity !== undefined &&
    typeof activity.avgActivity === 'number'
  ) {
    compositeActivity.avgActivity = {
      ons: activity.avgOns,
      offs: activity.avgOffs,
      total: activity.avgActivity,
    };
  }
  if (
    activity.sumActivity !== undefined &&
    typeof activity.sumActivity === 'number'
  ) {
    compositeActivity.sumActivity = {
      ons: activity.sumOns,
      offs: activity.sumOffs,
      total: activity.sumActivity,
    };
  }
  return compositeActivity;
};

const generateCompositeActivity = (response: any) => {
  const getActivity = (node: any) => {
    if (!node || typeof node !== 'object') {
      return node;
    }

    if (Array.isArray(node)) {
      for (let i = 0; i < node.length; i++) {
        node[i] = getActivity(node[i]);
      }
      return node;
    }

    if (node.activity !== undefined) {
      node.activity = generateCompositeMetrics(node.activity);
      return node;
    }

    const keys = Object.keys(node);
    const newNode: Record<string, any> = {};
    for (const key of keys) {
      newNode[key] = getActivity(node[key]);
    }
    return newNode;
  };

  return getActivity(response);
};

const remapDualDateRangeResponse = (results: ObjectMap[]) => {
  const remap = results.map((result) => {
    const first: ObjectMap = { ...result };
    const second: ObjectMap = { ...result };
    const percentChange: ObjectMap = { ...result };
    for (const [key, value] of Object.entries(result)) {
      if (typeof value === 'object') {
        if (value.first !== undefined) {
          first[key] = value.first;
        }
        if (value.second !== undefined) {
          second[key] = value.second;
        }
        if (value.percentChange !== undefined) {
          percentChange[key] = value.percentChange;
        }
      }
    }
    return { ...result, first, second, percentChange };
  });
  return remap;
};

const isLoadMetric = <T extends string>(kpi: T) => {
  return ['avgLoad', 'maxLoad', 'sumLoad', 'avgMaxLoad'].includes(kpi);
};

export {
  cacheResult,
  calculatePercentChange,
  changeKeytoSecond,
  checkCache,
  clearSessionStorage,
  combineDatesResult,
  combineDaysResult,
  combineInsightActivityResult,
  combinePeriodsResult,
  combineRoutesDetailResult,
  combineRoutesLoadResult,
  combineRoutesLoadResultV2,
  combineRoutesSummaryResult,
  combineRoutesSummaryResultV2,
  combineStopsDetailResult,
  combineStopsSummaryResult,
  combineStopsSummaryResultV2,
  combineSystemResult,
  combineTripDetailResult,
  combineTripSummaryResult,
  debounce,
  determineGroupByFromTotalDays,
  devWarning,
  filterEmptyProperties,
  formatAbbreviatedNumberString,
  formatNumberString,
  generateCompositeActivity,
  generateCompositeMetrics,
  generateDualDateRangeCompositeMetrics,
  getExpandedPercent,
  getExpandedPercentV2,
  getMapboxAccessToken,
  getCompositeActivityValue,
  getTotalDays,
  isLoadMetric,
  lossyDeepClone,
  metricV2ToV1,
  remapDualDateRangeResponse,
  roundToTwoDecimals,
  sleep,
  sortAlphaNum,
  stableSort,
};
