import React from 'react';
import { Button, ButtonProps, CircularProgress } from '@material-ui/core';

export type LoadingButtonProps = {
  'data-testid'?: string;
  loading?: boolean;
} & ButtonProps;

export default function LoadingButton({
  disabled,
  loading = false,
  children,
  ...rest
}: LoadingButtonProps) {
  return (
    <Button {...rest} disabled={loading || disabled}>
      {loading && <CircularProgress size={20} />}
      {!loading && children}
    </Button>
  );
}
