import React, { useState } from 'react';
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';

// styles
import useStyles from './styles';

// logo
import logo from '../../assets/hopthru.svg';

// context
import { setPassword } from '../../store/effects/user';
import { updateIsAuthenticated } from '../../store/slices/userSlice';
import { useDispatch } from 'react-redux';

const queryString = require('query-string');

function Reset(props) {
  var classes = useStyles();

  // global
  const dispatch = useDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(false);
  var [newPassword, setNewPassword] = useState('');
  var [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  let params = queryString.parse(props.history.location.search);

  function onEnterPressed() {
    if (newPassword !== confirmNewPassword) {
      setError('Password confimation does not match');
    } else {
      setError(false);
      dispatch(
        setPassword(
          params.token,
          newPassword,
          false,
          setIsLoading,
          setError,
          () => dispatch(updateIsAuthenticated(true))
        )
      );
    }
  }

  return (
    <Grid container className={classes.container}>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <img src={logo} alt='logo' className={classes.logotypeImage} />
          <Typography
            color='secondary'
            variant='h2'
            style={{ margin: 'auto', marginTop: 16 }}
          >
            Set a new password
          </Typography>
          <Typography color='secondary'>Password</Typography>
          <TextField
            id='password'
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                onEnterPressed();
                ev.preventDefault();
              }
            }}
            variant='outlined'
            type={showPassword ? 'text' : 'password'}
            fullWidth
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography color='secondary' style={{ marginTop: 4 }}>
            Confirm password
          </Typography>
          <TextField
            id='confirmPassword'
            value={confirmNewPassword}
            className={classes.textField}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                onEnterPressed();
                ev.preventDefault();
              }
            }}
            variant='outlined'
            type={showPassword ? 'text' : 'password'}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Fade in={error}>
            <Typography color='error' className={classes.errorMessage}>
              {error}
            </Typography>
          </Fade>
          <div className={classes.buttonContainer}>
            <Button
              className={classes.buttonStyle}
              style={{ width: '100%', height: 50 }}
              disabled={newPassword.length === 0}
              onClick={() => onEnterPressed()}
              variant='contained'
              color='secondary'
            >
              Set password
            </Button>
            {isLoading && (
              <CircularProgress
                size={26}
                color='secondary'
                style={{ marginTop: 16 }}
              />
            )}
          </div>
        </div>
        <Typography color='primary' className={classes.copyright}>
          © 2023 Hopthru, Inc. All rights reserved.
        </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(Reset);
