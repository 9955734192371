import React from 'react';

type MaybeWrapProps = {
  when: boolean;
  wrapper: React.ReactElement;
  children?: React.ReactNode;
};

export default function MaybeWrap({ when, wrapper, children }: MaybeWrapProps) {
  if (when) {
    return React.cloneElement(wrapper, { children });
  }

  return <>{children}</>;
}
