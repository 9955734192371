import { store } from '../store';
import moment from 'moment';
import queryString from 'query-string';

export const PALMTRAN_AGENCY_ID = '7ef3a2ba-9713-4e90-8f90-1cc7f12ba128';

function getAgencyId() {
  const params = queryString.parse(window.location.search);
  if (params && 'agencyId' in params && params.agencyId !== null) {
    return params.agencyId;
  }

  // This is hacky and won't work for isomorphic rendering,
  // but is a lighter way of accessing agency id prior to migrating
  // API methods to RTK Query or some other store-aware layer.
  const agency = store.getState().user.agency || {};
  return agency.agencyId;
}

function showPalmTranTripsWarning() {
  const params = queryString.parse(window.location.search);
  const agencyId = getAgencyId();
  if (
    agencyId === PALMTRAN_AGENCY_ID &&
    (window.location.pathname.includes('trips') ||
      window.location.search.includes('direction') ||
      window.location.search.includes('variant'))
  ) {
    const validTripStartDate = moment('2020-08-15');
    const firstDateRange = JSON.parse(params.firstDateRange);
    const firstStartDate = moment(firstDateRange.startDate);
    if (firstStartDate < validTripStartDate) return true;
    if (params.secondDateRange) {
      const secondDateRange = JSON.parse(params.secondDateRange);
      const secondStartDate = moment(secondDateRange.startDate);
      if (secondStartDate < validTripStartDate) return true;
    }
  }
  return false;
}

export { getAgencyId, showPalmTranTripsWarning };
