import { createSlice } from '@reduxjs/toolkit';
import history, {
  PARAMS_TYPE_JSON,
  PARAMS_TYPE_STRING,
} from '../../utils/history';
import { filterEquals } from '../../utils/filters';
import { filterEmptyProperties, lossyDeepClone } from '../../utils/utils';
import queryString from 'query-string';
import { createDeepCompareSelector } from './selectors';

function getParamState() {
  const newState = {};
  const params = queryString.parse(history.location.search, {
    arrayFormat: 'comma',
  });

  if (params) {
    for (let param of PARAMS_TYPE_JSON) {
      if (param in params) {
        try {
          newState[param] = JSON.parse(params[param]);
        } catch (e) {
          // do something, invalid json
        }
      }
    }

    for (let param of PARAMS_TYPE_STRING) {
      if (param in params) {
        newState[param] = params[param];
      }
    }
  }

  return newState;
}

export const initialState = getParamState();

export const analyticsFiltersSlice = createSlice({
  name: 'analyticsFilters',
  initialState,
  reducers: {
    addRouteFilter: (state, action) => {
      state.routeFilters = state.routeFilters || [];
      state.routeFilters.push(action.payload);
    },
    addStopFilter: (state, action) => {
      state.stopFilters = state.stopFilters || [];
      state.stopFilters.push(action.payload);
    },
    addTripFilter: (state, action) => {
      state.tripFilters = state.tripFilters || [];
      state.tripFilters.push(action.payload);
    },
    removeRouteFilter: (state, action) => {
      const index = state.routeFilters.findIndex((filter) =>
        filterEquals(filter, action.payload)
      );
      if (index > -1) {
        state.routeFilters.splice(index, 1);
      }
    },
    removeStopFilter: (state, action) => {
      const index = state.stopFilters.findIndex((filter) =>
        filterEquals(filter, action.payload)
      );
      if (index > -1) {
        state.stopFilters.splice(index, 1);
      }
    },
    removeTripFilter: (state, action) => {
      const index = state.tripFilters.findIndex((filter) =>
        filterEquals(filter, action.payload)
      );
      if (index > -1) {
        state.tripFilters.splice(index, 1);
      }
    },
    replaceFirstDateRange: (state, action) => {
      state.firstDateRange = action.payload;
    },
    replaceSecondDateRange: (state, action) => {
      state.secondDateRange = action.payload;
    },
    updateRouteFilter: (state, action) => {
      const { oldFilter, newFilter } = action.payload;
      const index = state.routeFilters.findIndex((filter) =>
        filterEquals(filter, oldFilter)
      );
      if (index > -1) {
        state.routeFilters[index] = newFilter;
      }
    },
    updateStopFilter: (state, action) => {
      const { oldFilter, newFilter } = action.payload;
      const index = state.stopFilters.findIndex((filter) =>
        filterEquals(filter, oldFilter)
      );
      if (index > -1) {
        state.stopFilters[index] = newFilter;
      }
    },
    updateTripFilter: (state, action) => {
      const { oldFilter, newFilter } = action.payload;
      const index = state.tripFilters.findIndex((filter) =>
        filterEquals(filter, oldFilter)
      );
      if (index > -1) {
        state.tripFilters[index] = newFilter;
      }
    },
    updateRouteFilters: (state, action) => {
      state.routeFilters = action.payload;
    },
    updateStopFilters: (state, action) => {
      state.stopFilters = action.payload;
    },
    updateTripFilters: (state, action) => {
      state.tripFilters = action.payload;
    },
    updateFirstDateRange: (state, action) => {
      // Avoid setting date range to an empty object by only setting if
      // date range already exists and changeset is not all empty (unset).

      const changeset = filterEmptyProperties(action.payload);
      if (!state.firstDateRange && Object.keys(changeset).length > 0) {
        state.firstDateRange = lossyDeepClone({
          ...state.firstDateRange,
          ...changeset,
        });
      } else if (state.firstDateRange) {
        state.firstDateRange = lossyDeepClone({
          ...state.firstDateRange,
          ...action.payload,
        });
      }
    },
    updateSecondDateRange: (state, action) => {
      // Avoid setting date range to an empty object by only setting if
      // date range already exists and changeset is not all empty (unset).

      const changeset = filterEmptyProperties(action.payload);
      if (!state.secondDateRange && Object.keys(changeset).length > 0) {
        state.secondDateRange = lossyDeepClone({
          ...state.secondDateRange,
          ...changeset,
        });
      } else if (state.secondDateRange) {
        state.secondDateRange = lossyDeepClone({
          ...state.secondDateRange,
          ...action.payload,
        });
      }
    },
    updateMetric: (state, action) => {
      state.metric = action.payload;
    },
    updateSortType: (state, action) => {
      state.sortType = action.payload;
    },
    updateSortDirection: (state, action) => {
      state.sortDirection = action.payload;
    },
    clearRouteFilters: (state) => {
      state.routeFilters = [];
    },
    clearStopFilters: (state) => {
      state.stopFilters = [];
    },
    clearTripFilters: (state) => {
      state.tripFilters = [];
    },
    clearFirstDateRange: (state) => {
      state.firstDateRange = undefined;
    },
    clearSecondDateRange: (state) => {
      state.secondDateRange = undefined;
    },
    clearMetric: (state) => {
      state.metric = '';
    },
    clearSortType: (state) => {
      state.sortType = '';
    },
    clearSortDirection: (state) => {
      state.sortDirection = '';
    },
    clearFilters: () => ({}),
  },
});

// ACTIONS
export const {
  addRouteFilter,
  addStopFilter,
  addTripFilter,
  clearRouteFilters,
  clearStopFilters,
  clearTripFilters,
  clearFirstDateRange,
  clearSecondDateRange,
  clearMetric,
  clearSortType,
  clearSortDirection,
  clearFilters,
  removeRouteFilter,
  removeStopFilter,
  removeTripFilter,
  replaceFirstDateRange,
  replaceSecondDateRange,
  updateRouteFilter,
  updateStopFilter,
  updateTripFilter,
  updateRouteFilters,
  updateStopFilters,
  updateTripFilters,
  updateFirstDateRange,
  updateSecondDateRange,
  updateMetric,
  updateSortType,
  updateSortDirection,
} = analyticsFiltersSlice.actions;

// SELECTORS
export const selectAnalyticsFilters = (state) => state.analyticsFilters;
export const selectRouteFilters = createDeepCompareSelector(
  (state) => state.analyticsFilters.routeFilters || [],
  (routeFilters) => routeFilters
);
export const selectStopFilters = createDeepCompareSelector(
  (state) => state.analyticsFilters.stopFilters || [],
  (stopFilters) => stopFilters
);
export const selectTripFilters = createDeepCompareSelector(
  (state) => state.analyticsFilters.tripFilters || [],
  (tripFilters) => tripFilters
);
export const selectFirstDateRange = createDeepCompareSelector(
  (state) => state.analyticsFilters.firstDateRange,
  (firstDateRange) => firstDateRange
);
export const selectSecondDateRange = createDeepCompareSelector(
  (state) => state.analyticsFilters.secondDateRange,
  (secondDateRange) => secondDateRange
);
export const selectMetric = (state) =>
  state.analyticsFilters.metric || 'avgOns';
export const selectSortType = (state) => state.analyticsFilters.sortType;
export const selectSortDirection = (state) =>
  state.analyticsFilters.sortDirection || 'desc';

// REDUCER
export default analyticsFiltersSlice.reducer;
