import React from 'react';
import clsx from 'clsx';
import {
  IconButton,
  IconButtonProps,
  Tooltip,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  headerMenuButtonActive: {
    backgroundColor: 'rgba(0,0,0,0.4)',
  },
  headerMenuButton: {
    border: 'none',
    borderRadius: theme.spacing(1),
    color: theme.palette.primary.main,
    fontSize: '1.5rem',
    height: theme.spacing(3),
    width: theme.spacing(3),
    '&.Mui-disabled': {
      color: theme.palette.primary.main,
    },
    '&.Mui-focusVisible': {
      outlineColor: theme.palette.primary.main,
    },
  },
  headerMenuButtonDark: {
    color: theme.palette.secondary.main,
    '&.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.26)',
    },
    '&.Mui-focusVisible': {
      outlineColor: theme.palette.secondary.main,
    },
  },
  alignLeft: { marginRight: theme.spacing(2) },
  alignRight: { marginLeft: theme.spacing(2) },
}));

type HeaderMenuButtonV2Props = {
  title: string;
  isActive?: boolean;
  className?: string;
  variant?: 'light' | 'dark';
  align?: 'left' | 'right';
} & IconButtonProps;

export default function HeaderMenuButtonV2({
  isActive,
  className,
  variant = 'light',
  title,
  align = 'right',
  ...props
}: HeaderMenuButtonV2Props) {
  const classes = useStyles();

  return (
    <Tooltip title={title}>
      <span>
        <IconButton
          title={title}
          {...props}
          className={clsx(classes.headerMenuButton, className, {
            [classes.headerMenuButtonActive]: isActive,
            [classes.headerMenuButtonDark]: variant === 'dark',
            [classes.alignLeft]: align === 'left',
            [classes.alignRight]: align === 'right',
          })}
        />
      </span>
    </Tooltip>
  );
}
