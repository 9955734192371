import React, { useState } from 'react';
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade,
  Divider,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';

// styles
import useStyles from './styles';

// logo
import logo from '../../assets/hopthru.svg';

// context
import { loginUser, sendResetPassword } from '../../store/effects/user';
import { enqueueToast } from '../../store/slices/layoutSlice';
import { useDispatch } from 'react-redux';

function Login(props) {
  var classes = useStyles();

  // global
  const dispatch = useDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(false);
  var [loginValue, setLoginValue] = useState('');
  var [passwordValue, setPasswordValue] = useState('');
  var [forgotPassword, setForgotPassword] = useState(false);

  const onEnterPressed = async () => {
    setError(false);
    if (!forgotPassword) {
      dispatch(loginUser(loginValue, passwordValue, setIsLoading, setError));
    } else {
      let result = await sendResetPassword(loginValue, setIsLoading, setError);
      if (result) {
        setForgotPassword(false);
        dispatch(
          enqueueToast({ severity: 'info', message: 'Reset email sent' })
        );
      }
    }
  };

  const loginForm = (
    <React.Fragment>
      <img src={logo} alt='logo' className={classes.logotypeImage} />
      <Typography
        color='secondary'
        variant='h2'
        style={{ margin: 'auto', marginTop: 16 }}
      >
        Welcome back.
      </Typography>
      <Divider className={classes.divider} />
      <Typography color='secondary' style={{ marginTop: 4 }}>
        Email address
      </Typography>
      <TextField
        id='email'
        value={loginValue}
        onChange={(e) => setLoginValue(e.target.value)}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            onEnterPressed();
            ev.preventDefault();
          }
        }}
        type='email'
        variant='outlined'
        fullWidth
      />
      <Typography color='secondary' style={{ marginTop: 8 }}>
        Password
      </Typography>
      <TextField
        id='password'
        value={passwordValue}
        onChange={(e) => setPasswordValue(e.target.value)}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            onEnterPressed();
            ev.preventDefault();
          }
        }}
        type='password'
        variant='outlined'
        fullWidth
      />
      <Fade in={error}>
        <Typography color='error' className={classes.errorMessage}>
          Incorrect username or password
        </Typography>
      </Fade>
      <div className={classes.buttonContainer}>
        <Button
          disableElevation
          className={classes.buttonStyle}
          style={{ width: '100%', height: 50 }}
          disabled={loginValue.length === 0 || passwordValue.length === 0}
          onClick={() => onEnterPressed()}
          variant='contained'
          color='secondary'
        >
          Log In
        </Button>
        {isLoading ? (
          <CircularProgress
            size={26}
            color='secondary'
            style={{ marginTop: 16 }}
          />
        ) : (
          <Button
            className={classes.buttonStyle}
            onClick={() => setForgotPassword(!forgotPassword)}
            style={{ borderRadius: 25 }}
            variant='outlined'
            color='tertiary'
          >
            Forgot your password?
          </Button>
        )}
      </div>
    </React.Fragment>
  );

  const resetForm = (
    <React.Fragment>
      <img src={logo} alt='logo' className={classes.logotypeImage} />
      <Divider className={classes.divider} />
      <Typography
        color='secondary'
        variant='h2'
        style={{ margin: 'auto', marginTop: 16 }}
      >
        Reset Password.
      </Typography>
      <Typography
        color='tertiary'
        style={{ marginBottom: 4, textAlign: 'center' }}
      >
        Please enter the email address associated with your Hopthru account, and
        we’ll email you a link to reset your password.
      </Typography>
      <Typography color='secondary' style={{ marginTop: 4 }}>
        Email address
      </Typography>
      <TextField
        id='email'
        value={loginValue}
        onChange={(e) => setLoginValue(e.target.value)}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            onEnterPressed();
            ev.preventDefault();
          }
        }}
        type='email'
        variant='outlined'
        fullWidth
      />
      <Fade in={error}>
        <Typography color='error' className={classes.errorMessage}>
          Email not found
        </Typography>
      </Fade>
      <div className={classes.buttonContainer}>
        <Button
          disablElevation
          className={classes.buttonStyle}
          style={{ width: '100%', height: 50 }}
          disabled={loginValue.length === 0}
          onClick={() => onEnterPressed()}
          variant='contained'
          color='secondary'
        >
          Send reset link
        </Button>
        {isLoading ? (
          <CircularProgress
            size={26}
            color='secondary'
            style={{ marginTop: 16 }}
          />
        ) : (
          <Button
            className={classes.buttonStyle}
            onClick={() => setForgotPassword(!forgotPassword)}
            style={{ borderRadius: 25 }}
            variant='outlined'
            color='tertiary'
          >
            Return to login
          </Button>
        )}
      </div>
    </React.Fragment>
  );

  return (
    <Grid container className={classes.container}>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          {!forgotPassword ? loginForm : resetForm}
        </div>
        <Typography color='primary' className={classes.copyright}>
          © 2023 Hopthru, Inc. All rights reserved.
        </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(Login);
