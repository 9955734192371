import React from 'react';
import { Typography, CircularProgress } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/ReportProblemOutlined';
// styles
import useStyles from './styles';

export default function Loading({ showError, message }) {
  var classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.container}>
        {!showError ? (
          <>
            <CircularProgress
              color='secondary'
              size={12}
              className={classes.loadingSpinner}
            />
            <Typography color='secondary' className={classes.details}>
              {message ? message : 'Chart loading'}
            </Typography>
          </>
        ) : (
          <>
            <ErrorIcon
              color='warning'
              size={12}
              className={classes.errorIcon}
            />
            <Typography color='secondary' className={classes.details}>
              Error loading data
            </Typography>
          </>
        )}
      </div>
    </React.Fragment>
  );
}
