import { MetricsV1 } from '../../store/types';

export enum InsightType {
  ROUTES = 'routes',
  STOPS = 'stops',
  TRIPS = 'trips',
  SYSTEM = 'system',
  LAYERS = 'layers',
  SEGMENTS = 'segments',
}

export enum InsightGrouping {
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
  DAY_OF_WEEK = 'dayofweek',
  PERIOD = 'timerange',
  WEEKDAY_SAT_SUN = 'weekdaysatsun',
}

export enum InsightFilterType {
  ROUTE = 'routes',
  STOP = 'stops',
  TRIP = 'trips',
  LAYER = 'layers',
  LAYER_GROUP = 'layerGroup',
  KPI = 'kpi',
  START_TIME = 'startTime',
  END_TIME = 'endTime',
  TAG = 'tag',
  DIRECTION = 'direction',
  VARIANT = 'variant',
}

export enum InsightFilterComparison {
  GREATER_THAN = 'greaterThan',
  LESS_THAN = 'lessThan',
  EQUALS = 'equals',
}

export enum InsightFilterInclusion {
  IS_IN = 'in',
  IS_NOT_IN = 'notIn',
}

export type InsightComparisonFilter = {
  type: InsightFilterType;
  comparison: InsightFilterComparison;
  value: string;
};

export type InsightKpiFilter = {
  kpi: keyof MetricsV1;
} & InsightComparisonFilter;

export type InsightDirectionFilter = {
  type: InsightFilterType;
  value: string;
};

export type InsightEntityFilter = {
  type: InsightFilterType;
  filterIds: string[];
  comparison: InsightFilterInclusion;
};

export type InsightFilter =
  | InsightComparisonFilter
  | InsightKpiFilter
  | InsightEntityFilter
  | InsightDirectionFilter;

export type ViewLayer = {
  id: string;
  type: string;
  name: string;
};

export type Legend = {
  [key: string]: number[];
};

export type InsightLegend = {
  singleDateLegend?: Legend;
  dualDateLegend?: Legend;
};

export type Insight = {
  id: string;
  creatorName: string;
  creatorId: string;
  agencyId: string;
  timeRange?: string;
  name: string;
  insightType: InsightType;
  public?: boolean;
  groupBy?: InsightGrouping;
  expanded?: boolean;
  dateRange?: RelativeDateRange;
  dateRanges?: RelativeDateRange[];
  filters?: InsightFilter[];
  displayColumns?: (keyof InsightActivity)[];
  displayLayers?: ViewLayer[];
  displayLegend?: InsightLegend;
  isSubscribed?: boolean;
  isFavorited?: boolean;
  hidden?: boolean;
};

export enum InsightCreatedBy {
  ME,
  NOT_ME,
  ANYONE,
}

export type InsightActivity = {
  hour?: string;
  day?: string;
  week?: string;
  month?: string;
  year?: string;
  dayofweek?: string;
  timerange?: string;
  id?: string;
  routeId?: string;
  gtfsStopCode?: string;
  gtfsStopId?: string;
  gtfsStopName?: string;
  gtfsStopLat?: number;
  gtfsStopLon?: number;
  gtfsStopSequence?: number;
  gtfsDepartureTime?: string;
  followingId?: string;
  followingGtfsStopCode?: string;
  followingGtfsStopId?: string;
  followingGtfsStopName?: string;
  gtfsRouteId?: string;
  gtfsRouteShortName?: string;
  layerName?: string;
  variant?: string;
  direction?: number;
  startTime?: string;
  endTime?: string;
  length?: string;
} & MetricsV1;
