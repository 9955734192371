import defaultTheme from './default';

import { createMuiTheme } from '@material-ui/core';
import type { ThemeOptions } from '@material-ui/core/styles';

const overrides = {
  typography: {
    h1: {
      fontSize: '3rem',
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.64rem',
    },
    h4: {
      fontSize: '1.375rem',
    },
    h5: {
      fontSize: '1.285rem',
    },
    h6: {
      fontSize: '1.142rem',
    },
  },
};

type HopthruTheme = {
  [Key in keyof typeof defaultTheme]: typeof defaultTheme[Key];
};

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    tertiary: Palette['primary'];
  }
  interface PaletteOptions {
    tertiary: PaletteOptions['primary'];
  }
}

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme extends HopthruTheme {}
  interface ThemeOptions extends HopthruTheme {}
}

const theme = {
  default: createMuiTheme({
    ...defaultTheme,
    ...overrides,
  } as ThemeOptions),
};

export default theme;
