import React, { useEffect, useState } from 'react';
import { Chat, ChatOutlined } from '@material-ui/icons';
import HeaderMenuButton from '../HeaderMenuButton';
import HeaderMenuButtonV2 from '../HeaderMenuButton/HeaderMenuButtonV2';
import { makeStyles } from '@material-ui/styles';
import { postEvent } from '../../utils/api';

const useStyles = makeStyles(() => ({
  chatIcon: {
    color: 'secondary',
  },
}));

type ChatButtonProps = { useV2?: boolean };

export default function ChatButton({ useV2 }: ChatButtonProps) {
  const [chatActive, setChatActive] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    window.Intercom?.('onShow', function () {
      setChatActive(true);
      postEvent('support_chat_opened');
    });
    window.Intercom?.('onHide', function () {
      setChatActive(false);
    });
  }, []);

  const Button = useV2 ? HeaderMenuButtonV2 : HeaderMenuButton;

  return (
    <Button
      aria-controls='profile-help'
      aria-haspopup='true'
      aria-label='Help'
      className=''
      color='inherit'
      id='help_button'
      isActive={chatActive}
      title='Help'
    >
      {chatActive ? (
        <Chat fontSize='inherit' classes={{ root: classes.chatIcon }} />
      ) : (
        <ChatOutlined fontSize='inherit' classes={{ root: classes.chatIcon }} />
      )}
    </Button>
  );
}
